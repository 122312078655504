import { Box, ClickAwayListener, ListItem, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCoaches } from 'pages/backoffice/api/coaches/use-coaches/useCoaches';
import { MetadataCoach } from 'pages/backoffice/types/metadata-coaches';
import { RenderSelectOption } from 'pages/backoffice/ui/RenderSelectOption';
import { Autocomplete } from 'shared/components/autocomplete/Autocomplete';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/ui/AutocompleteDropdownButton';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperContent';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperWrapper';
import { AutocompleteResultsWrapper } from 'shared/components/autocomplete-multi-select/ui/AutocompleteResultsWrapper';

export type CoachesFilters = {
  name: string;
};

const INITIAL_RECORDING_COACHES_FILTERS: CoachesFilters = {
  name: '',
};

interface Props {
  setCoachOnChange: (coach: MetadataCoach | undefined) => void;
  coach?: MetadataCoach;
  disabled?: boolean;
}

const AUTOCOMPLETE_WIDTH = 400;

export const SelectCoach = ({ setCoachOnChange, coach }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<MetadataCoach | undefined>(coach);
  const { items: results, isLoading, setFilters, fetchNextPage } = useCoaches();
  const { t } = useTranslation();

  const isOpen = Boolean(anchorEl);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValue(coach || undefined);
      setAnchorEl(event.currentTarget);
    },
    [coach],
  );

  const handleClose = useCallback(() => {
    setFilters(INITIAL_RECORDING_COACHES_FILTERS);
    setAnchorEl(null);
  }, [setFilters]);

  const handleDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? handleClose() : handleOpen(event);
    },
    [isOpen, handleClose, handleOpen],
  );

  const handleSelectCoach = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      setCoachOnChange(autocompleteValue);
      return autocompleteValue;
    });
    handleClose();
  }, [setAutocompleteValue, setCoachOnChange, handleClose]);

  const handleSetName = useCallback(
    (name: string) => {
      setFilters({ name: name });
    },
    [setFilters],
  );

  const renderOption = useCallback((props: React.ComponentProps<typeof ListItem>, option: MetadataCoach) => {
    return <RenderSelectOption {...props} key={option.id} name={option.name} />;
  }, []);

  const handleApply = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      return autocompleteValue;
    });
    handleClose();
  }, [setAutocompleteValue, handleClose]);

  const handleReset = useCallback(() => {
    setAutocompleteValue(undefined);
  }, [setAutocompleteValue]);

  const paperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    (props) => (
      <AutocompleteResultsWrapper {...props} onReset={handleReset} onApply={handleApply}>
        {props.children}
      </AutocompleteResultsWrapper>
    ),
    [handleReset, handleApply],
  );

  const handleUpdateValue = useCallback(
    (item: MetadataCoach | null) => {
      if (item === null) return;
      setAutocompleteValue(item);
      setCoachOnChange(item);
      handleClose();
    },
    [setAutocompleteValue, setCoachOnChange, handleClose],
  );

  const selectedValue = results.find((c) => autocompleteValue?.id === c.id) ?? autocompleteValue;

  return (
    <Stack direction={'column'} spacing={1}>
      <AutocompleteDropdownButton
        fullWidth
        isOpen={isOpen}
        onClick={handleDropdownClick}
        isSelected={Boolean(coach)}
        label={coach ? coach.name : 'Select coach'}
      />
      <Box>
        {anchorEl && (
          <ClickAwayListener onClickAway={handleSelectCoach}>
            <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
              <AutocompletePopperContent elevation={8}>
                <Autocomplete
                  autoFocus
                  PaperComponent={paperComponent}
                  PopperComponent={AutocompletePopperWrapper}
                  fetchNextPage={fetchNextPage}
                  getItemLabel={(item) => item.name}
                  inputWidth={AUTOCOMPLETE_WIDTH}
                  isLoading={isLoading}
                  listWidth={AUTOCOMPLETE_WIDTH}
                  multiple={false}
                  onChange={handleSetName}
                  open
                  options={results}
                  renderOption={renderOption}
                  placeholder={t('common:actions.search')}
                  resultsHeight={260}
                  updateValue={handleUpdateValue}
                  value={selectedValue}
                />
              </AutocompletePopperContent>
            </AutocompletePopper>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  );
};
