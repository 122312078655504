import { Box, Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { CompetitionAvatar } from 'kognia-ui/components/avatar/CompetitionAvatar';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import React, { useCallback, useState } from 'react';

import { useInvalidateCompetitions } from 'pages/backoffice/api/competitions/use-competitions/useCompetitions';
import { useDeleteCompetition } from 'pages/backoffice/api/competitions/useDeleteCompetition';
import { useUpdateCompetition } from 'pages/backoffice/api/competitions/useUpdateCompetition';
import { CompetitionFormFieldsNames } from 'pages/backoffice/pages/competitions/ui/competition-form/types';
import { CompetitionsListItem } from 'pages/backoffice/pages/competitions/ui/competition-item/styled';
import { CompetitionDetailsModal } from 'pages/backoffice/pages/competitions/ui/CompetitionDetailsModal';
import { CompetitionModal } from 'pages/backoffice/pages/competitions/ui/CompetitionModal';
import { MetadataCompetition } from 'pages/backoffice/types/metadata-competitions';
import { MetadataMapping } from 'pages/backoffice/types/metadata-mappings';
import { MappingsModal } from 'pages/backoffice/ui/mappings-modal/MappingsModal';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';

interface Props {
  competition: MetadataCompetition;
}

export const CompetitionItem = ({ competition }: Props) => {
  const { update, isPending } = useUpdateCompetition();
  const invalidateCompetitions = useInvalidateCompetitions();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteCompetition } = useDeleteCompetition();
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const [isMappingsModalOpen, setIsMappingsModalOpen] = useState(false);
  const handleMappingsModalOpen = () => setIsMappingsModalOpen(true);
  const handleMappingsModalClose = () => setIsMappingsModalOpen(false);

  const handleDeleteCompetition = useCallback(() => {
    deleteCompetition({
      competitionId: competition.id,
    });
  }, [competition.id, deleteCompetition]);

  const options: MenuListOption[] = [
    {
      displayText: 'Details',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => setIsDetailsModalOpen(true),
    },
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Mappings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleMappingsModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const defaultValues = {
    [CompetitionFormFieldsNames.id]: competition.id,
    [CompetitionFormFieldsNames.name]: competition.name,
    [CompetitionFormFieldsNames.country]: competition.country ? competition.country : '',
    [CompetitionFormFieldsNames.tier]: competition.tier ? competition.tier.toString() : '',
    [CompetitionFormFieldsNames.format]: competition.format ? competition.format : '',
    [CompetitionFormFieldsNames.gender]: competition.gender ? competition.gender : '',
    [CompetitionFormFieldsNames.region]: competition.region ? competition.region : '',
    [CompetitionFormFieldsNames.teamType]: competition.teamType ? competition.teamType : '',
    [CompetitionFormFieldsNames.organizationId]: competition.organization ? competition.organization.id : '',
  };

  const handleUpdateMappings = useCallback(
    (mappings: MetadataMapping[]) => {
      update({
        id: competition.id,
        data: {
          mappings: mappings
            .filter((mapping) => mapping.idProvider)
            .map((mapping) => ({
              provider: mapping.provider,
              competition_id_provider: mapping.idProvider,
            })),
        },
        onSuccess: () => {
          invalidateCompetitions();
        },
      });
    },
    [update, competition.id, invalidateCompetitions],
  );

  return (
    <>
      <CompetitionsListItem key={competition.id}>
        <Stack direction={'row'}>
          <Box mr={2}>
            <CompetitionAvatar src={competition.logoUrl} />
          </Box>
          <Typography fontWeight={fontWeight['500']}>{competition.name}</Typography>
        </Stack>
        <Typography>{competition.country}</Typography>
        <Typography fontWeight={fontWeight['500']}>{competition.format}</Typography>
        <KebabMenu options={options} />
      </CompetitionsListItem>
      {isEditModalOpen ? (
        <CompetitionModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={defaultValues} />
      ) : null}

      {isDetailsModalOpen ? (
        <CompetitionDetailsModal competition={competition} onClose={() => setIsDetailsModalOpen(false)} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${competition.name}" competition?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteCompetition}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />

      {isMappingsModalOpen ? (
        <MappingsModal
          id={competition.id}
          isLoading={isPending}
          mappings={competition.mappings}
          onClose={handleMappingsModalClose}
          title={`Competition mappings - ${competition.name}`}
          update={handleUpdateMappings}
        />
      ) : null}
    </>
  );
};
