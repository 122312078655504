import { Box } from '@mui/material';
import { Breakpoint, generateContainerQueries } from 'kognia-ui';
import { memo } from 'react';

import { usePlayer } from 'api/player/usePlayer';
import { PlayerProfileInfo } from 'features/dashboard/player-profile-header/ui/PlayerProfileInfo';
import { PlayerProfileStatsFeature } from 'features/dashboard/player-profile-stats/PlayerProfileStats.feature';

type Props = {
  playerId: string;
  competitionId?: string;
  seasonYear?: number;
};

export const PlayerProfileHeaderWidget = memo(({ competitionId, seasonYear, playerId }: Props) => {
  const { data: player } = usePlayer(playerId);

  return (
    <Box sx={{ containerType: 'inline-size' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
          ...generateContainerQueries({
            [Breakpoint.MD]: {
              flexDirection: 'row',
              gap: 2,
            },
          }),
        }}
      >
        <PlayerProfileInfo player={player} />
        <PlayerProfileStatsFeature playerId={player.id} competitionId={competitionId} seasonYear={seasonYear} />
      </Box>
    </Box>
  );
});

PlayerProfileHeaderWidget.displayName = 'PlayerProfileHeaderWidget';
