import { CoachApi } from 'pages/backoffice/api/coaches/types';
import { FixtureApi, MetaDataPlayerApi } from 'pages/backoffice/api/fixtures/types';
import { MetadataFixturesApiResponse } from 'pages/backoffice/api/fixtures/use-metadata-fixtures/types';
import { transformSeason } from 'pages/backoffice/api/seasons/transformers';
import { TeamApi } from 'pages/backoffice/api/teams/types';
import { chooseNextCursorValue } from 'pages/backoffice/api/utils/chooseNextCursorValue';
import { MetadataCoach } from 'pages/backoffice/types/metadata-coaches';
import { MetadataFixturePlayer, MetadataFixture, MetadataTeam } from 'pages/backoffice/types/metadata-fixtures';

export const transformMetadataTeam = (team: TeamApi): MetadataTeam => ({
  id: team.uuid,
  name: team.name,
  abbreviation: team.abbreviation,
  logoUrl: team.logo_url,
  teamType: team.team_type,
  countryCode: team.country_code,
});

export const transformMetadataCoach = (coach: CoachApi | null): MetadataCoach | undefined => {
  if (!coach) return undefined;

  return {
    id: coach.uuid,
    name: coach.name,
    photoUrl: coach.photo_url,
  };
};

const transformMetadataPlayer = (player: MetaDataPlayerApi): MetadataFixturePlayer => {
  return {
    id: player.player_uuid,
    name: player.player_name,
    jerseyNumber: player.jersey_number,
    photoUrl: player.player_photo_url,
    side: player.side,
    position: player.position,
    teamId: player.team_uuid,
    mappings: player.player_mappings?.map(({ player_id_provider, provider }) => ({
      provider,
      idProvider: player_id_provider,
    })),
  };
};

export const transformMetadataFixture = (item: FixtureApi): MetadataFixture => ({
  id: item.uuid,
  name: item.name,
  duration: item.duration,
  homeTeamScore: item.home_team_score,
  awayTeamScore: item.away_team_score,
  date: item.date ?? undefined,
  pitchLength: item.pitch_length,
  pitchWidth: item.pitch_width,
  homePenaltyScore: item.home_penalty_score,
  awayPenaltyScore: item.away_penalty_score,
  matchEndType: item.match_end_type,
  homeTeam: transformMetadataTeam(item.home_team),
  awayTeam: transformMetadataTeam(item.away_team),
  homeCoach: transformMetadataCoach(item.home_coach),
  awayCoach: transformMetadataCoach(item.away_coach),
  venue: item.venue
    ? {
        id: item.venue.uuid,
        name: item.venue.name,
        city: item.venue.city,
        countryCode: item.venue.country_code,
      }
    : undefined,
  stage: {
    id: item.stage.uuid,
    name: item.stage.name,
    orderIndex: item.stage.order_index,
  },
  season: transformSeason(item.season),
  players: item.players.map(transformMetadataPlayer),
  recordings: item.recordings.map((recording) => ({
    clientIds: recording.client_ids,
    createdAt: recording.created_at,
    id: recording.uuid,
    recordingType: recording.recording_type,
    isCanonical: recording.is_canonical,
  })),
  mappings: item.mappings.map(({ provider, fixture_id_provider }) => ({
    provider,
    idProvider: fixture_id_provider,
  })),
});

const transformMetadataFixtures = (items: FixtureApi[]): MetadataFixture[] => items.map(transformMetadataFixture);

export const transformMetadataFixturesFromApiResponse = (response: MetadataFixturesApiResponse) => ({
  data: {
    items: transformMetadataFixtures(response.data),
    page: response.page,
  },
  nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
});
