import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { transformMetadataPlayerApiResponse } from 'pages/backoffice/api/players/use-players/transformer';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';
import { MetadataPlayer, MetadataPlayerApi } from 'pages/backoffice/types/metadata-players';

type CreatePlayerParams = Pick<MetadataPlayerApi, 'name'> & { image?: File };

interface CreatePlayerData {
  data: CreatePlayerParams;
  onSuccess?: (player: MetadataPlayer) => void;
}

export const useCreatePlayer = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<MetadataPlayerApi, MetadataPlayer, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Player created',
    transformer: transformMetadataPlayerApiResponse,
    errorMessage: (data) => metadataApiErrorParser(data, 'Player creation error'),
  });

  const createPlayer = ({ data, onSuccess }: CreatePlayerData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('player', JSON.stringify({ name: data.name }));
    data.image && formData.append('player_image', data.image);

    mutationRequest.mutate(
      {
        url: annotationApiUrls.PLAYERS,
        data: formData,
      },
      { onSuccess },
    );
  };

  return { ...mutationRequest, createPlayer };
};
