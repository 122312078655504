import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformMetadataFixture } from 'pages/backoffice/api/fixtures/transformers';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { MetadataFixture } from 'pages/backoffice/types/metadata-fixtures';

type Params = {
  fixtureId?: string;
  onSuccess?: (data: MetadataFixture) => void;
};

export const getMetadataFixtureQueryKey = (fixtureId?: string) => ['metadata-fixture', fixtureId ?? ''];

export const useMetadataFixture = ({ fixtureId }: Params): UseQueryResult<MetadataFixture> => {
  return useFetchRequest<MetadataFixture>({
    queryRef: getMetadataFixtureQueryKey(fixtureId),
    url: `${annotationApiUrls.FIXTURES}/${fixtureId}`,
    transformer: transformMetadataFixture,
    options: {
      enabled: fixtureId !== undefined,
    },
  });
};

export const useInvalidateMetadataFixture = () => {
  const queryClient = useQueryClient();

  return (fixtureId: string) => queryClient.invalidateQueries({ queryKey: getMetadataFixtureQueryKey(fixtureId) });
};
