import { useCallback } from 'react';

import { useAddPlayersToFixture } from 'pages/backoffice/api/fixtures/use-add-players-to-fixture/addPlayersToFixtureErrorParser';
import { useDeleteFixturesPlayers } from 'pages/backoffice/api/fixtures/use-delete-metadata-fixture-players/useDeleteFixturesPlayers';
import { useInvalidateMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture/useMetadataFixture';
import { useUpdateMetadataFixture } from 'pages/backoffice/api/fixtures/use-update-metadata-fixture/useUpdateMetadataFixture';
import { FixtureInfoSchema } from 'pages/backoffice/pages/fixtures/fixture-form/forms';
import { NewFixtureTeam } from 'pages/backoffice/pages/fixtures/fixture-form/types';
import { MetadataFixture } from 'pages/backoffice/types/metadata-fixtures';
import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';

export const useUpdateNewFixtureWithPlayers = (onSuccess: (fixture: MetadataFixture) => void) => {
  const { update } = useUpdateMetadataFixture();
  const { create: addPlayersToFixture } = useAddPlayersToFixture();
  const { deleteFixturesPlayers } = useDeleteFixturesPlayers();
  const invalidateMetadataFixture = useInvalidateMetadataFixture();

  const handleAddNewPlayerToFixture = useCallback(
    (fixtureId: string, homeTeam: NewFixtureTeam, awayTeam: NewFixtureTeam, onSuccess: (data: any) => void) =>
      addPlayersToFixture({
        fixtureId,
        players: [
          ...homeTeam.players.map((p) => ({
            jersey_number: p.jerseyNumber ?? 0,
            side: p.side,
            position: p.position,
            player_uuid: p.id,
            team_uuid: homeTeam.team.id,
          })),
          ...awayTeam.players.map((p) => ({
            jersey_number: p.jerseyNumber ?? 0,
            side: p.side,
            position: p.position,
            player_uuid: p.id,
            team_uuid: awayTeam.team.id,
          })),
        ],
        onSuccess: (data: MetadataFixture) => {
          onSuccess(data);
        },
      }),
    [addPlayersToFixture],
  );

  return useCallback(
    (fixtureId: string, fixtureInfo: FixtureInfoSchema, homeTeam: NewFixtureTeam, awayTeam: NewFixtureTeam) => {
      update({
        id: fixtureId,
        data: {
          name: fixtureInfo.name,
          duration: fixtureInfo.duration,
          home_team_score: fixtureInfo.scoreHomeTeam,
          away_team_score: fixtureInfo.scoreOpponentTeam,
          pitch_length: fixtureInfo.pitchLength,
          pitch_width: fixtureInfo.pitchWidth,
          home_penalty_score: 0,
          away_penalty_score: 0,
          match_end_type: FixtureMatchEndType.NORMAL_TIME,
          home_team_uuid: homeTeam?.team.id ?? '',
          away_team_uuid: awayTeam?.team.id ?? '',
          stage_uuid: fixtureInfo.stageId,
          ...(fixtureInfo?.homeCoach?.id ? { home_coach_uuid: fixtureInfo.homeCoach.id } : {}),
          ...(fixtureInfo?.awayCoach?.id ? { away_coach_uuid: fixtureInfo.awayCoach.id } : {}),
          ...(fixtureInfo.date ? { date: fixtureInfo.date } : {}),
          ...(fixtureInfo.venue ? { venue_uuid: fixtureInfo.venue.id } : {}),
        },
        onSuccess: () => {
          invalidateMetadataFixture(fixtureId);
        },
      });

      deleteFixturesPlayers({
        data: { fixtureId },
        onSuccess: () => handleAddNewPlayerToFixture(fixtureId, homeTeam, awayTeam, onSuccess),
      });
    },
    [invalidateMetadataFixture, handleAddNewPlayerToFixture, deleteFixturesPlayers, update, onSuccess],
  );
};
