import { nativeEnum, object, string, z } from 'zod';

export enum MetadataMappingProvider {
  KOGNIA = 'kognia',
  CHYRONHEGO = 'chyronhego',
  SECOND_SPECTRUM = 'second-spectrum',
  HAWKEYE = 'hawkeye',
  SIFNALITY = 'signality',
  SIFNALITY_KOGNIA = 'signality-kognia',
  SPORT_LOGIQ = 'sportlogiq',
  OPTA = 'opta',
  BUNDES_LIGA = 'bundesliga',
}

export const metadataMappingSchema = object({
  provider: nativeEnum(MetadataMappingProvider),
  idProvider: string().min(1),
});

export type MetadataMapping = z.infer<typeof metadataMappingSchema>;
