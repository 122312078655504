import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { queryClient } from 'api/config';
import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { teamWithDefaults } from 'api/routes';
import { transformTeam } from 'entities/dashboard/api/use-team-with-defaults/transformer';
import { TeamWithPlayers } from 'shared/types/team/team';

const TEAM_WITH_DEFAULTS_QUERY_KEY = 'team-with-defaults';

export const generateTeamWithDefaultsQueryKey = (teamId: string) => [TEAM_WITH_DEFAULTS_QUERY_KEY, teamId];

export const invalidateTeamWithDefaults = (teamId: string) => {
  return queryClient.invalidateQueries({ queryKey: generateTeamWithDefaultsQueryKey(teamId) });
};

export const optimisticFavoritesUpdateTeamWithDefaults = async (teamId: string, isFavorite: boolean) => {
  const queryKey = generateTeamWithDefaultsQueryKey(teamId);
  await queryClient.cancelQueries({ queryKey });

  queryClient.setQueryData(queryKey, (team: TeamWithPlayers) => {
    return {
      ...team,
      isFavorite,
    };
  });
};

export const useTeamWithDefaults = (teamId: string): UseSuspenseQueryResult<TeamWithPlayers> => {
  return useFetchSuspenseRequest<TeamWithPlayers>({
    url: teamWithDefaults(teamId),
    queryRef: generateTeamWithDefaultsQueryKey(teamId),
    transformer: transformTeam,
  });
};
