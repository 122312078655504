import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { CreateStageApiParams } from 'pages/backoffice/api/stages/use-create-stage/types';
import { useInvalidateStages } from 'pages/backoffice/api/stages/use-stages/useStages';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';

interface RequestData {
  data: CreateStageApiParams;
  seasonId: string;
  onSuccess?: () => void;
}

export const useCreateStage = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateStages = useInvalidateStages();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Stage created',
    errorMessage: (data) => metadataApiErrorParser(data, 'Stage creation error'),
    onSuccess: invalidateStages,
  });

  const create = ({ data, onSuccess, seasonId }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: annotationApiUrls.SEASON_COMPETITIONS_STAGES(seasonId),
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
