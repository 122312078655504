import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { useInvalidateMetadataFixtures } from 'pages/backoffice/api/fixtures/use-metadata-fixtures/useMetadataFixtures';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';

interface DeleteMetadataFixtureOptions {
  id: string;
  onSuccess?: () => void;
}

type Options = {
  successMessage?: string;
};

export const useDeleteMetadataFixture = ({ successMessage }: Options = { successMessage: 'Fixture deleted' }) => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateMetadataFixtures = useInvalidateMetadataFixtures();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: successMessage,
    errorMessage: (data) => metadataApiErrorParser(data, 'Fixture delete error'),
    onSuccess: invalidateMetadataFixtures,
  });

  const deleteMetadataFixture = ({ id, onSuccess }: DeleteMetadataFixtureOptions) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.FIXTURES}/${id}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteMetadataFixture };
};
