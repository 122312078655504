import { TypeOf, z } from 'zod';

import { metadataVenueSchema } from 'pages/backoffice/api/venues/use-venues/types';
import { fixturesPlayerSchema, metadataTeamSchema } from 'pages/backoffice/types/metadata-fixtures';
import { metadataSeasonSchema } from 'pages/backoffice/types/metadata-seasons';
import { numberTextField } from 'shared/utils/zod/number-text-field';

export enum TeamFormFieldsNames {
  team = 'team',
  players = 'players',
}

export const fixtureTeamFormSchema = z.object({
  [TeamFormFieldsNames.team]: metadataTeamSchema,
  [TeamFormFieldsNames.players]: z
    .array(fixturesPlayerSchema)
    .min(1, { message: 'Players selection is required' })
    .refine(
      (players) => {
        const jerseyNumbers = players.map((player) => player.jerseyNumber);
        return jerseyNumbers.length === new Set(jerseyNumbers).size;
      },
      { message: 'Duplicated jersey numbers are not allowed' },
    )
    .refine(
      (players) => {
        return players.every((player) => player.jerseyNumber !== 0);
      },
      (val) => {
        const playerNames = val.filter((player) => player.jerseyNumber === 0).map((player) => player.name);
        return { message: `Incorrect jersey number (0) for players (${playerNames})` };
      },
    ),
});

export type TeamFormFormSchema = TypeOf<typeof fixtureTeamFormSchema>;

export enum FixtureInfoFormFieldsNames {
  name = 'name',
  date = 'date',
  startTime = 'startTime',
  scoreHomeTeam = 'scoreHomeTeam',
  scoreOpponentTeam = 'scoreOpponentTeam',
  duration = 'duration',
  venue = 'venue',
  season = 'season',
  stageId = 'stageId',
  pitchLength = 'pitchLength',
  pitchWidth = 'pitchWidth',
  homeCoach = 'homeCoach',
  awayCoach = 'awayCoach',
}

export enum CoachFieldsNames {
  id = 'id',
  name = 'name',
  photoUrl = 'photoUrl',
}

const coachSchema = z.object({
  [CoachFieldsNames.id]: z.string(),
  [CoachFieldsNames.name]: z.string().trim().min(2),
  [CoachFieldsNames.photoUrl]: z.string().url().or(z.literal('')).nullable().optional(),
});

export const fixtureInfoFormSchema = z.object({
  [FixtureInfoFormFieldsNames.name]: z.string().min(3),
  [FixtureInfoFormFieldsNames.date]: z.string().optional(),
  [FixtureInfoFormFieldsNames.scoreHomeTeam]: numberTextField,
  [FixtureInfoFormFieldsNames.scoreOpponentTeam]: numberTextField,
  [FixtureInfoFormFieldsNames.duration]: numberTextField,
  [FixtureInfoFormFieldsNames.season]: metadataSeasonSchema,
  [FixtureInfoFormFieldsNames.venue]: metadataVenueSchema.optional(),
  [FixtureInfoFormFieldsNames.stageId]: z.string().min(1, 'Required'),
  [FixtureInfoFormFieldsNames.pitchLength]: numberTextField,
  [FixtureInfoFormFieldsNames.pitchWidth]: numberTextField,
  [FixtureInfoFormFieldsNames.homeCoach]: coachSchema.optional(),
  [FixtureInfoFormFieldsNames.awayCoach]: coachSchema.optional(),
});

export type FixtureInfoSchema = TypeOf<typeof fixtureInfoFormSchema>;
