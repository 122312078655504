import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { RerunEventsPreprocessApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';

interface RerunEventsPreprocessParams {
  data: RerunEventsPreprocessApiParams;
}

export const useRerunEventsPreprocess = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Rerun events preprocess success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Rerun events preprocess error'),
  });

  const rerunEventsPreprocess = ({ data }: RerunEventsPreprocessParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.EVENTS_PREPROCESS}`, data });
  };

  return { ...mutationRequest, rerunEventsPreprocess };
};
