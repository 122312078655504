import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormControl, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import { useCallback, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useInvalidateSeasons } from 'pages/backoffice/api/seasons/use-seasons/useSeasons';
import {
  FORM_IDS,
  useFixtureInfo,
  useSetFixtureInfo,
  useTeamData,
} from 'pages/backoffice/pages/fixtures/fixture-form/config';
import {
  FixtureInfoFormFieldsNames,
  fixtureInfoFormSchema,
  FixtureInfoSchema,
} from 'pages/backoffice/pages/fixtures/fixture-form/forms';
import { useCreateNewFixtureWithPlayers } from 'pages/backoffice/pages/fixtures/fixture-form/ui/fixture-info-form/use-create-new-fixture-with-players/useCreateNewFixtureWithPlayers';
import { useUpdateNewFixtureWithPlayers } from 'pages/backoffice/pages/fixtures/fixture-form/ui/fixture-info-form/use-update-new-fixture-with-players/useUpdateNewFixtureWithPlayers';
import { SelectSeason } from 'pages/backoffice/pages/stages/components/select-season';
import { MetadataCoach } from 'pages/backoffice/types/metadata-coaches';
import { MetadataFixture, MetadataTeam } from 'pages/backoffice/types/metadata-fixtures';
import { SelectVenue } from 'pages/backoffice/ui/select-venue/SelectVenue';
import { SelectCoach } from 'pages/backoffice/ui/SelectCoach';
import { StageModal } from 'pages/backoffice/ui/StageModal';
import { FormFormLabel } from 'shared/components/form/ui/FormFormLabel';
import { FormSelectField } from 'shared/components/form/ui/FormSelectField';
import { getTypeOptions } from 'shared/components/form/utils/getTypeOptions';
import { MatchTeamTypes } from 'shared/types/match';

type Props = {
  step: number;
  fixture?: MetadataFixture;
};

const generateDefaultTitle = (homeTeam: MetadataTeam | undefined, awayTeam: MetadataTeam | undefined) => {
  if (!homeTeam || !awayTeam) return '';

  return `${homeTeam.abbreviation.toUpperCase()} - ${awayTeam.abbreviation.toUpperCase()}`;
};

export const FixtureInfoForm = ({ step, fixture }: Props) => {
  const navigate = useNavigate();
  const fixtureInfo = useFixtureInfo();
  const setfixtureInfo = useSetFixtureInfo();
  const { id } = useParams();
  const invalidateSeasons = useInvalidateSeasons();
  const [isCreateStageModalOpen, setIsCreateStageModalOpen] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    control,
  } = useForm<FixtureInfoSchema>({
    resolver: zodResolver(fixtureInfoFormSchema),
    defaultValues: fixtureInfo ? { ...fixtureInfo, season: fixture?.season } : undefined,
  });

  const season = watch(FixtureInfoFormFieldsNames.season);
  const stageId = watch(FixtureInfoFormFieldsNames.stageId);
  const homeTeam = useTeamData(MatchTeamTypes.HOME);
  const awayTeam = useTeamData(MatchTeamTypes.OPPONENT);
  const fixtureInfoFormRef = useRef<HTMLFormElement>(null);
  const createFixture = useCreateNewFixtureWithPlayers((fixture: MetadataFixture) => {
    navigate(generatePath(routes.BACKOFFICE_NEW_FIXTURE_SUMMARY, { id: fixture.id }));
  });
  const updateFixture = useUpdateNewFixtureWithPlayers((fixture: MetadataFixture) => {
    navigate(generatePath(routes.BACKOFFICE_NEW_FIXTURE_SUMMARY, { id: fixture.id }));
  });

  const handleSetTeamData = useCallback(
    (fixtureInfo: FixtureInfoSchema) => {
      if (!homeTeam || !awayTeam) return;

      setfixtureInfo(fixtureInfo);
      id ? updateFixture(id, fixtureInfo, homeTeam, awayTeam) : createFixture(fixtureInfo, homeTeam, awayTeam);
    },
    [id, updateFixture, setfixtureInfo, awayTeam, homeTeam, createFixture],
  );

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    return handleSubmit(handleSetTeamData)(event);
  };

  return (
    <>
      <form id={FORM_IDS[step]} ref={fixtureInfoFormRef} onSubmit={handleOnSubmit}>
        <Grid container justifyContent={'center'} sx={{ paddingBottom: 4 }}>
          <Grid item xs={12} md={6}>
            <Stack gap={2}>
              <FormControl fullWidth>
                <FormFormLabel>Name</FormFormLabel>
                <TextField
                  defaultValue={generateDefaultTitle(homeTeam?.team, awayTeam?.team)}
                  size='small'
                  {...register(FixtureInfoFormFieldsNames.name)}
                  error={!!errors[FixtureInfoFormFieldsNames.name]}
                  helperText={
                    errors[FixtureInfoFormFieldsNames.name] && <>{errors[FixtureInfoFormFieldsNames.name].message}</>
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <FormFormLabel optional>Date</FormFormLabel>
                <TextField
                  size='small'
                  fullWidth
                  type='datetime-local'
                  {...register(FixtureInfoFormFieldsNames.date)}
                  error={!!errors[FixtureInfoFormFieldsNames.date]}
                  helperText={
                    errors[FixtureInfoFormFieldsNames.date] && <>{errors[FixtureInfoFormFieldsNames.date].message}</>
                  }
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormFormLabel>Score</FormFormLabel>
                    <Stack direction={'row'} gap={1}>
                      <TextField
                        type={'number'}
                        InputProps={{ inputProps: { min: 0 } }}
                        size='small'
                        {...register(FixtureInfoFormFieldsNames.scoreHomeTeam)}
                        error={!!errors[FixtureInfoFormFieldsNames.scoreHomeTeam]}
                        helperText={
                          errors[FixtureInfoFormFieldsNames.scoreHomeTeam] && (
                            <>{errors[FixtureInfoFormFieldsNames.scoreHomeTeam].message}</>
                          )
                        }
                      />
                      <TextField
                        type={'number'}
                        InputProps={{ inputProps: { min: 0 } }}
                        size='small'
                        {...register(FixtureInfoFormFieldsNames.scoreOpponentTeam)}
                        error={!!errors[FixtureInfoFormFieldsNames.scoreOpponentTeam]}
                        helperText={
                          errors[FixtureInfoFormFieldsNames.scoreOpponentTeam] && (
                            <>{errors[FixtureInfoFormFieldsNames.scoreOpponentTeam].message}</>
                          )
                        }
                      />
                    </Stack>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormFormLabel>Duration</FormFormLabel>
                    <TextField
                      type='number'
                      InputProps={{ inputProps: { min: 0 } }}
                      size='small'
                      {...register(FixtureInfoFormFieldsNames.duration)}
                      error={!!errors[FixtureInfoFormFieldsNames.duration]}
                      helperText={
                        errors[FixtureInfoFormFieldsNames.duration] && (
                          <>{errors[FixtureInfoFormFieldsNames.duration].message}</>
                        )
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <FormFormLabel optional>Home coach</FormFormLabel>
                <Controller
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <SelectCoach setCoachOnChange={onChange} coach={value} />
                      {error && <Typography color={Colors.red}>{error.message}</Typography>}
                    </>
                  )}
                  name={FixtureInfoFormFieldsNames.homeCoach}
                  control={control}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormFormLabel optional>Away coach</FormFormLabel>
                <Controller
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <SelectCoach
                        setCoachOnChange={(coach: MetadataCoach | undefined) => {
                          onChange(coach);
                        }}
                        coach={value || undefined}
                      />
                      {error && <Typography color={Colors.red}>{error.message}</Typography>}
                    </>
                  )}
                  name={FixtureInfoFormFieldsNames.awayCoach}
                  control={control}
                />
              </FormControl>

              <Controller
                name={FixtureInfoFormFieldsNames.season}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControl fullWidth>
                    <FormFormLabel>Season</FormFormLabel>
                    <SelectSeason
                      setSeasonOnChange={(season) => {
                        onChange(season);
                        if (!(fixture?.season?.id === season?.id && fixtureInfo?.stageId === stageId)) {
                          setValue(FixtureInfoFormFieldsNames.stageId, '');
                        }
                      }}
                      selectedSeasonId={value?.id}
                    />
                    {error && <Typography color={Colors.red}>{error.message}</Typography>}
                  </FormControl>
                )}
              />

              {Boolean(season) && (
                <FormControl fullWidth>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <FormFormLabel>Stages</FormFormLabel>
                    <Box>
                      <Button onClick={() => setIsCreateStageModalOpen(true)}>+ new stage</Button>
                    </Box>
                  </Stack>
                  <Controller
                    name={FixtureInfoFormFieldsNames.stageId}
                    control={control}
                    render={({ field: { value, ...restFieldProps }, fieldState: { error } }) => (
                      <>
                        {season && season.stages && season.stages.length > 0 ? (
                          <FormSelectField
                            id={FixtureInfoFormFieldsNames.stageId}
                            options={getTypeOptions({
                              options: season.stages.map((stage) => ({
                                value: stage.id,
                                label: stage.name,
                              })),
                            })}
                            error={!!error}
                            helperText={error && error?.message}
                            value={value}
                            fullWidth={true}
                            {...restFieldProps}
                          />
                        ) : (
                          <>{error && <Typography color={Colors.red}>{error.message}</Typography>}</>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              )}

              <FormControl fullWidth>
                <FormFormLabel optional>Venue</FormFormLabel>
                <Controller
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <SelectVenue setVenueOnChange={onChange} venue={value} />
                      {error && <Typography color={Colors.red}>{error.message}</Typography>}
                    </>
                  )}
                  name={FixtureInfoFormFieldsNames.venue}
                  control={control}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormFormLabel>Pitch Size</FormFormLabel>
                <Stack direction={'row'} gap={2}>
                  <TextField
                    size='small'
                    type='number'
                    variant='outlined'
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Typography component='span' color='secondary'>
                            Length
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    {...register(FixtureInfoFormFieldsNames.pitchLength)}
                    error={!!errors[FixtureInfoFormFieldsNames.pitchLength]}
                    helperText={
                      errors[FixtureInfoFormFieldsNames.pitchLength] && (
                        <>{errors[FixtureInfoFormFieldsNames.pitchLength].message}</>
                      )
                    }
                  />

                  <TextField
                    size='small'
                    type='number'
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Typography component='span' color='secondary'>
                            Width
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    {...register(FixtureInfoFormFieldsNames.pitchWidth)}
                    error={!!errors[FixtureInfoFormFieldsNames.pitchWidth]}
                    helperText={
                      errors[FixtureInfoFormFieldsNames.pitchWidth] && (
                        <>{errors[FixtureInfoFormFieldsNames.pitchWidth].message}</>
                      )
                    }
                  />
                </Stack>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {isCreateStageModalOpen ? (
        <StageModal
          open={isCreateStageModalOpen}
          onClose={() => setIsCreateStageModalOpen(false)}
          onSuccess={invalidateSeasons}
        />
      ) : null}
    </>
  );
};
