import { useQueries, UseQueryResult } from '@tanstack/react-query';

import { callBackendApi } from 'api/hooks/callBackendApi';
import { HTTPMethod } from 'api/types';
import { transformMetadataFixture } from 'pages/backoffice/api/fixtures/transformers';
import { getMetadataFixtureQueryKey } from 'pages/backoffice/api/fixtures/use-metadata-fixture/useMetadataFixture';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { MetadataFixture } from 'pages/backoffice/types/metadata-fixtures';

export const useMetadataFixtureQueries = (fixtureIds: string[] = []) => {
  const fixtureQueries = fixtureIds.map((fixtureId) => {
    return {
      queryKey: getMetadataFixtureQueryKey(fixtureId),
      queryFn: () =>
        callBackendApi(`${annotationApiUrls.FIXTURES}/${fixtureId}`, HTTPMethod.GET, transformMetadataFixture),
    };
  });

  return useQueries<MetadataFixture[]>({
    queries: fixtureQueries,
  }) as UseQueryResult<MetadataFixture>[];
};
