import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { MetadataSeasonApi } from 'pages/backoffice/api/seasons/types';
import { SeasonsApiResponse } from 'pages/backoffice/api/seasons/use-seasons/types';
import { transformStageSummaries } from 'pages/backoffice/api/stages/use-stages/transformer';
import { chooseNextCursorValue } from 'pages/backoffice/api/utils/chooseNextCursorValue';
import { MetadataSeason } from 'pages/backoffice/types/metadata-seasons';

export const transformSeason = (item: MetadataSeasonApi): MetadataSeason => ({
  id: item.uuid,
  year: item.year,
  crossesYear: item.crosses_year,
  startDate: item.start_date,
  endDate: item.end_date,
  name: item.name,
  competition: {
    id: item.competition.uuid,
    name: item.competition.name,
    country: item.competition.country,
    logoUrl: item.competition.logo_url,
  },
  stages: transformStageSummaries(item.stages),
});

export const transformSeasons = (response: SeasonsApiResponse): InfinityQueryDataResult<MetadataSeason> => {
  return {
    data: {
      items: response.data.map(transformSeason),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
