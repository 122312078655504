import { TypeOf } from 'zod';

import {
  createPlayerFormSchema,
  editPlayerFormSchema,
} from 'pages/backoffice/pages/players/components/player-form/form';
import { MetadataPlayerMapping } from 'pages/backoffice/types/metadata-players';

export enum PlayerFormFieldsNames {
  name = 'name',
  playerImage = 'playerImage',
  mappings = 'mappings',
}

export interface CreatePlayerForm {
  [PlayerFormFieldsNames.name]: string;
  [PlayerFormFieldsNames.playerImage]?: FileList;
  [PlayerFormFieldsNames.mappings]?: MetadataPlayerMapping[];
}

export type CreatePlayerFormSchema = TypeOf<typeof createPlayerFormSchema>;

export interface EditPlayerForm {
  [PlayerFormFieldsNames.name]: string;
  [PlayerFormFieldsNames.playerImage]?: FileList;
  [PlayerFormFieldsNames.mappings]?: MetadataPlayerMapping[];
}

export type EditPlayerFormSchema = TypeOf<typeof editPlayerFormSchema>;
