import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { useInvalidateCompetitions } from 'pages/backoffice/api/competitions/use-competitions/useCompetitions';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';

interface DeleteCompetitionParams {
  competitionId: string;
  onSuccess?: () => void;
}

export const useDeleteCompetition = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateCompetitions = useInvalidateCompetitions();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: 'Delete competition success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Delete competition error'),
    onSuccess: invalidateCompetitions,
  });

  const deleteCompetition = ({ competitionId, onSuccess }: DeleteCompetitionParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.COMPETITIONS}/${competitionId}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteCompetition };
};
