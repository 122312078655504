import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useCompetitionsState } from 'entities/competitions/store/steps';
import { useTeamWithDefaults } from 'entities/dashboard/api/use-team-with-defaults/useTeamWithDefaults';
import { getPlayerPositionFromValue } from 'entities/dashboard/types/dashboard';
import { NameRow } from 'features/competitions/players-step/ui/name-row/NameRow';
import { routes } from 'kognia/router/routes';
import { DashboardMenuItem } from 'shared/components/DashboardMenuItem';
import { DashboardMenuList } from 'shared/components/DashboardMenuList';
import { MenuItemContentWrapper } from 'shared/components/MenuItemContentWrapper';
import { invariant } from 'shared/utils/invariant';

export const PlayersStepFeature = () => {
  const { team } = useCompetitionsState();
  invariant(team, 'team is undefined in PlayersStepFeature');
  const { data: teamData } = useTeamWithDefaults(team.id);
  const { t } = useTranslation(['explore', 'player']);
  const navigate = useNavigate();

  const rows = useMemo(() => {
    if (!teamData?.players?.length) {
      return [];
    }

    return teamData?.players.map(({ id, position, name, photoUrl }) => {
      return {
        id,
        name,
        photoUrl,
        position,
      };
    });
  }, [teamData?.players]);

  const goToPlayerProfile = (playerId: string) => {
    navigate(generatePath(routes.PLAYER_PROFILE, { playerId }));
  };

  if (!rows.length) {
    return null;
  }

  return (
    <DashboardMenuList>
      {rows.map(({ id, name, photoUrl, position }) => (
        <DashboardMenuItem
          key={id}
          tabIndex={0}
          onClick={() => goToPlayerProfile(id)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              goToPlayerProfile(id);
            }
          }}
        >
          <MenuItemContentWrapper gridTemplateColumns={'auto 33%'}>
            <NameRow name={name} photoUrl={photoUrl} />
            <Typography
              color={'secondary'}
              component={'span'}
              overflow={'hidden'}
              textAlign={'center'}
              textOverflow={'ellipsis'}
              variant={'caption'}
              title={t(`player:position.${getPlayerPositionFromValue(position)}`)}
            >
              {t(`player:position.${getPlayerPositionFromValue(position)}`)}
            </Typography>
          </MenuItemContentWrapper>
        </DashboardMenuItem>
      ))}
    </DashboardMenuList>
  );
};
