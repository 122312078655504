import { Button, Grid } from '@mui/material';
import { useState } from 'react';

import { MetadataStageMiniSummary, MetadataStageSummary } from 'pages/backoffice/types/metadata-stages';

type Props = {
  stages: MetadataStageSummary[];
};

type StageProps = {
  name: string;
  childStages?: MetadataStageMiniSummary[] | null;
  nested?: boolean;
};

const orderIndex = (a: MetadataStageMiniSummary, b: MetadataStageMiniSummary) => a.orderIndex - b.orderIndex;

const StageItem = ({ name, childStages, nested = false }: StageProps) => {
  return (
    <Grid item sx={{ paddingLeft: nested ? '4px' : 0 }}>
      {nested ? '↳ ' : ''}
      {name}
      {childStages
        ? childStages
            .sort(orderIndex)
            .map((childStage) => <StageItem key={childStage.id} name={childStage.name} nested />)
        : null}
    </Grid>
  );
};

export const SeasonCompetitionStages = ({ stages }: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const filteredStages = stages.filter((stage) => !stage.parentStage).sort(orderIndex);
  const stagesChunk1 = filteredStages.slice(0, 5);
  const stagesChunk2 = filteredStages.slice(5, filteredStages.length);

  return (
    <Grid container direction={'column'} spacing={1}>
      {stagesChunk1.map((stage) => (
        <StageItem key={stage.id} name={stage.name} childStages={stage.childStages} />
      ))}
      {stagesChunk2.length > 0 && !collapsed
        ? stagesChunk2.map((stage) => <StageItem key={stage.id} name={stage.name} childStages={stage.childStages} />)
        : null}
      {stagesChunk2.length > 0 && collapsed ? (
        <Button size='small' onClick={() => setCollapsed(false)}>
          ...
        </Button>
      ) : null}
      {stagesChunk2.length > 0 && !collapsed ? (
        <Button size='small' onClick={() => setCollapsed(true)}>
          hide
        </Button>
      ) : null}
    </Grid>
  );
};
