import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { useInvalidateCompetitions } from 'pages/backoffice/api/competitions/use-competitions/useCompetitions';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';
import { UpdateMetadataCompetitionApiParams } from 'pages/backoffice/pages/competitions/ui/competition-form/types';

interface RequestData {
  data: UpdateMetadataCompetitionApiParams;
  id: string;
  onSuccess?: () => void;
}

export const useUpdateCompetition = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateCompetitions = useInvalidateCompetitions();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Competition updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Competition update error'),
    onSuccess: invalidateCompetitions,
  });

  const update = ({ data: { competitionImage, ...rest }, id, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('competition', JSON.stringify(rest));

    if (competitionImage?.[0]) {
      formData.append('competition_image', competitionImage[0]);
    }

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.COMPETITIONS}/${id}`,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
