import { List } from '@mui/material';

import { FixtureItem } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/FixtureItem';
import { MetadataFixture } from 'pages/backoffice/types/metadata-fixtures';

interface Props {
  fixtures: MetadataFixture[];
}

export const FixturesList = ({ fixtures }: Props) => {
  return (
    <List>
      {fixtures.map((fixture) => (
        <FixtureItem key={fixture.id} fixture={fixture} />
      ))}
    </List>
  );
};
