import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { chooseNextCursorValue } from 'pages/backoffice/api/utils/chooseNextCursorValue';
import {
  MetadataPlayer,
  MetadataPlayerApi,
  MetadataPlayerApiResponse,
  metadataPlayerSchema,
} from 'pages/backoffice/types/metadata-players';

export const transformMetadataPlayerApiResponse = (player: MetadataPlayerApi): MetadataPlayer => {
  return {
    id: player.uuid,
    name: player.name,
    photoUrl: player.photo_url,
    fixtures: player.fixtures.map((fixture) => ({
      id: fixture.uuid,
      name: fixture.name,
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      homePenaltyScore: fixture.home_penalty_score,
      awayPenaltyScore: fixture.away_penalty_score,
      matchEndType: fixture.match_end_type,
      position: fixture.position,
      side: fixture.side,
      jerseyNumber: fixture.jersey_number,
      date: fixture.date,
      team: {
        id: fixture.team.uuid,
        name: fixture.team.name,
        abbreviation: fixture.team.abbreviation,
        logoUrl: fixture.team.logo_url,
        teamType: fixture.team.team_type,
        countryCode: fixture.team.country_code,
      },
    })),
    mappings: player.mappings.map(({ provider, player_id_provider }) => ({
      provider,
      idProvider: player_id_provider,
    })),
    ...(player.defaults
      ? {
          default: {
            competition: {
              country: player.defaults.competition.country,
              id: player.defaults.competition.uuid,
              name: player.defaults.competition.name,
              format: player.defaults.competition.format,
              gender: player.defaults.competition.gender,
              logoUrl: player.defaults.competition.logo_url,
              region: player.defaults.competition.region,
              teamType: player.defaults.competition.team_type,
              tier: player.defaults.competition.tier,
              mappings: player.defaults.competition.mappings.map(({ provider, competition_id_provider }) => ({
                provider,
                idProvider: competition_id_provider,
              })),
              organization: player.defaults.competition.organization
                ? {
                    id: player.defaults.competition.organization.uuid,
                    name: player.defaults.competition.organization.name,
                    logoUrl: player.defaults.competition.organization.logo_url,
                  }
                : null,
              seasons: player.defaults.competition.seasons.map(
                ({ uuid, crosses_year, name, year, start_date, end_date }) => ({
                  id: uuid,
                  crossesYear: crosses_year,
                  name,
                  year,
                  startDate: start_date,
                  endDate: end_date,
                }),
              ),
            },
            season: {
              competition: {
                country: player.defaults.season.competition.country,
                id: player.defaults.season.competition.uuid,
                name: player.defaults.season.competition.name,
                logoUrl: player.defaults.season.competition.logo_url,
              },
              crossesYear: player.defaults.season.crosses_year,
              id: player.defaults.season.uuid,
              name: player.defaults.season.name,
              startDate: player.defaults.season.start_date,
              endDate: player.defaults.season.end_date,
              stages: player.defaults.season.stages.map(({ name, child_stages, order_index, parent_stage, uuid }) => ({
                name,
                id: uuid,
                childStages: child_stages
                  ? child_stages.map((child_stage) => ({
                      id: child_stage.uuid,
                      name: child_stage.name,
                      orderIndex: child_stage.order_index,
                    }))
                  : null,
                orderIndex: order_index,
                parentStage: parent_stage
                  ? {
                      id: parent_stage?.uuid,
                      name: parent_stage?.name,
                      orderIndex: parent_stage?.order_index,
                    }
                  : null,
              })),
              year: player.defaults.season.year,
            },
            team: {
              id: player.defaults.team.uuid,
              name: player.defaults.team.name,
              teamType: player.defaults.team.team_type,
              abbreviation: player.defaults.team.abbreviation,
              countryCode: player.defaults.team.country_code,
              logoUrl: player.defaults.team.logo_url,
            },
          },
        }
      : {}),
  };
};

export const transformPlayersApiResponseWithValidation = (players: MetadataPlayerApi[]): MetadataPlayer[] => {
  return players.map((player) => {
    const transformedPlayer = transformMetadataPlayerApiResponse(player);

    const validationResult = metadataPlayerSchema.safeParse(transformedPlayer);
    if (validationResult.error) {
      throw new Error('Invalid Player type', { cause: validationResult.error });
    }

    return transformedPlayer;
  });
};
export const transformPlayersResponse = (
  response: MetadataPlayerApiResponse,
): InfinityQueryDataResult<MetadataPlayer> => {
  const items = transformPlayersApiResponseWithValidation(response.data);

  return {
    data: {
      items,
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
