import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { queryClient } from 'api/config';
import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { playerUrl } from 'api/routes';
import { Player } from 'shared/types/player/player';
import { transformPlayerApiResponse } from 'shared/utils/transformPlayer';

const PLAYER_QUERY_KEY = 'player';

const generateQueryKey = (playerId: string) => [PLAYER_QUERY_KEY, playerId];

export const invalidatePlayer = (playerId: string) => {
  return queryClient.invalidateQueries({ queryKey: generateQueryKey(playerId) });
};

export const optimisticUpdatePlayerIsFavorite = async (playerId: string, isFavorite: boolean) => {
  const queryKey = generateQueryKey(playerId);
  await queryClient.cancelQueries({ queryKey });

  queryClient.setQueryData(queryKey, (player: Player) => {
    return {
      ...player,
      isFavorite,
    };
  });
};

export const usePlayer = (playerId: string): UseSuspenseQueryResult<Player> => {
  return useFetchSuspenseRequest<Player>({
    url: playerUrl(playerId),
    queryRef: generateQueryKey(playerId),
    transformer: transformPlayerApiResponse,
  });
};
