import { array, number, object, string, z } from 'zod';

import { metadataMappingSchema } from 'pages/backoffice/types/metadata-mappings';
import { metadataOrganizationSchema } from 'pages/backoffice/types/metadata-organizations';
import { metadataSeasonSummarySchema } from 'pages/backoffice/types/metadata-seasons';
import { MetadataTeamTypes } from 'pages/backoffice/types/metadata-teams';

export enum MetadataCompetitionFormat {
  LEAGUE = 'league',
  CUP = 'cup',
}

export enum MetadataCompetitionGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum MetadataCompetitionRegion {
  DOMESTIC = 'domestic',
  INTERNATIONAL = 'international',
}

export const metadataCompetitionSchema = object({
  country: string().nullable(),
  format: z.nativeEnum(MetadataCompetitionFormat).nullable(),
  gender: z.nativeEnum(MetadataCompetitionGender).nullable(),
  id: string(),
  logoUrl: string().nullable(),
  mappings: array(metadataMappingSchema).optional(),
  name: string(),
  region: z.nativeEnum(MetadataCompetitionRegion).nullable(),
  teamType: z.nativeEnum(MetadataTeamTypes).nullable(),
  tier: number().nullable(),
});

export const metadataCompetitionWithSeasonsAndOrganizationsSchema = metadataCompetitionSchema.merge(
  object({
    seasons: array(metadataSeasonSummarySchema),
    organization: metadataOrganizationSchema.nullable(),
  }),
);

export type MetadataCompetition = z.infer<typeof metadataCompetitionWithSeasonsAndOrganizationsSchema>;
