import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { CoachApi } from 'pages/backoffice/api/coaches/types';
import { CoachesApiResponse } from 'pages/backoffice/api/coaches/use-coaches/types';
import { chooseNextCursorValue } from 'pages/backoffice/api/utils/chooseNextCursorValue';
import { MetadataCoach } from 'pages/backoffice/types/metadata-coaches';

const transformData = (items: CoachApi[]): MetadataCoach[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    photoUrl: item.photo_url,
    mappings: item.mappings.map((mapping) => ({
      provider: mapping.provider,
      idProvider: mapping.coach_id_provider,
    })),
  }));
};

export const transformCoaches = (response: CoachesApiResponse): InfinityQueryDataResult<MetadataCoach> => {
  return {
    data: {
      items: transformData(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
