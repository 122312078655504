import { zodResolver } from '@hookform/resolvers/zod';
import { Button, MenuItem, Select, Stack, TextField } from '@mui/material';
import { IconUser } from 'kognia-ui/icons/IconUser';
import map from 'lodash/map';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TypeOf } from 'zod';

import {
  MetadataMappingProvider,
  MetadataMapping,
  metadataMappingSchema,
} from 'pages/backoffice/types/metadata-mappings';
import { findProviderMapping } from 'pages/backoffice/ui/mappings-modal/util/findProviderMapping';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog/ConfirmPopoverDialog';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';
import { FormFormLabel } from 'shared/components/form/ui/FormFormLabel';

interface Props {
  title: string;
  onClose: () => void;
  id: string;
  mappings?: MetadataMapping[];
  isLoading: boolean;
  update: (mappings: MetadataMapping[]) => void;
}

const defaultProvider = MetadataMappingProvider.OPTA;

export enum MappingFormSchemaNames {
  provider = 'provider',
  idProvider = 'idProvider',
}

export type MappingFormSchema = TypeOf<typeof metadataMappingSchema>;

export const MappingsModal = ({ title, onClose, mappings, update, isLoading }: Props) => {
  const {
    register,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<MappingFormSchema>({
    resolver: zodResolver(metadataMappingSchema),
    defaultValues: {
      [MappingFormSchemaNames.provider]: defaultProvider,
      [MappingFormSchemaNames.idProvider]: findProviderMapping(defaultProvider, mappings) || '',
    },
  });

  const editAction = useCallback(
    (mapping: MappingFormSchema) => {
      update([mapping]);
      onClose();
    },
    [update, onClose],
  );

  const idProvider = watch(MappingFormSchemaNames.idProvider);
  const formRef = useRef<HTMLFormElement>(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false);

  const handleModalSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  const handleEditMapping = useCallback(
    (mapping: MappingFormSchema) => {
      findProviderMapping(mapping.provider, mappings) && !isOverwriteModalOpen
        ? setIsOverwriteModalOpen(true)
        : editAction(mapping);
    },
    [editAction, mappings, isOverwriteModalOpen],
  );

  return (
    <DialogNew
      isLoading={isLoading}
      fullWidth
      maxWidth='md'
      title={title}
      icon={<IconUser />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
      onSubmit={handleModalSubmit}
      buttonSubmitText={'Done'}
      submitDisabled={!isDirty}
    >
      <form ref={formRef} onSubmit={handleSubmit(handleEditMapping)}>
        <Stack gap={2}>
          <FormFormLabel>Provider</FormFormLabel>
          <Select size={'small'} variant={'outlined'} value={defaultProvider}>
            {map(Object.keys(MetadataMappingProvider), (providerKey: keyof typeof MetadataMappingProvider) => (
              <MenuItem key={providerKey} value={MetadataMappingProvider[providerKey]}>
                {MetadataMappingProvider[providerKey]}
              </MenuItem>
            ))}
          </Select>
          <FormFormLabel>Mapping</FormFormLabel>
          <Stack direction={'row'} spacing={1}>
            <TextField
              sx={{ flexGrow: 1 }}
              size='small'
              {...register(MappingFormSchemaNames.idProvider)}
              error={!!errors[MappingFormSchemaNames.idProvider]}
              helperText={
                errors[MappingFormSchemaNames.idProvider] && <>{errors[MappingFormSchemaNames.idProvider].message}</>
              }
            />
            {Boolean(idProvider) && Boolean(findProviderMapping(defaultProvider, mappings)) && (
              <Button size={'small'} variant={'contained'} color={'error'} onClick={() => setIsRemoveModalOpen(true)}>
                Remove
              </Button>
            )}
          </Stack>
        </Stack>
        {isOverwriteModalOpen ? (
          <ConfirmPopoverDialog
            anchorEl={formRef.current}
            cancelLabel={'Cancel'}
            confirmLabel={'Overwrite'}
            description={'This action will overwrite the existing mapping'}
            isOpen={isOverwriteModalOpen}
            onConfirm={() => formRef.current?.requestSubmit()}
            setIsOpen={setIsOverwriteModalOpen}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          />
        ) : null}
        {isRemoveModalOpen ? (
          <ConfirmPopoverDialog
            anchorEl={formRef.current}
            cancelLabel={'Cancel'}
            confirmLabel={'Remove'}
            description={'This action will remove the existing mapping'}
            isOpen={isRemoveModalOpen}
            onConfirm={() => editAction({ provider: defaultProvider, idProvider: '' })}
            setIsOpen={setIsRemoveModalOpen}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          />
        ) : null}
      </form>
    </DialogNew>
  );
};
