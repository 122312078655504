import { z } from 'zod';

import { playerApiResponseSchema, playerApiSchema } from 'shared/types/player/playerApiSchema';
import { playerBackendApiResponseSchema } from 'shared/types/player/playerBackendApiSchema';
import { playerSchema } from 'shared/types/player/playerSchema';

export type AppPlayerApi = z.infer<typeof playerApiSchema>;

export type PlayerApiResponse = z.infer<typeof playerApiResponseSchema>;

export type PlayerBackendApiResponse = z.infer<typeof playerBackendApiResponseSchema>;

export type Player = z.infer<typeof playerSchema>;

export type PlayerApiFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
  isFavorite?: boolean;
};

export const PLAYER_STAT = {
  appearances: 'appearances',
  totalMinutes: 'totalMinutes',
  minutesStarting: 'minutesStarting',
  goals: 'goals',
  assists: 'assists',
  yellowCards: 'yellowCards',
  redCards: 'redCards',
} as const;

export type PlayerStats = {
  [PLAYER_STAT.appearances]: number;
  [PLAYER_STAT.totalMinutes]: number;
  [PLAYER_STAT.minutesStarting]: number;
  [PLAYER_STAT.goals]: number;
  [PLAYER_STAT.assists]: number;
  [PLAYER_STAT.yellowCards]: number;
  [PLAYER_STAT.redCards]: number;
  totalTeamMinutes: number;
};
