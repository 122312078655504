import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { transformPlayersResponse } from 'pages/backoffice/api/players/use-players/transformer';
import { playersWithFiltersUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import {
  MetadataPlayer,
  MetadataPlayerApiFilters,
  MetadataPlayerApiResponse,
} from 'pages/backoffice/types/metadata-players';
import { SortDirection } from 'shared/types/filters/types';

const PLAYERS_QUERY_KEY = 'players';

export const usePlayers = (): InfinityQueryResult<MetadataPlayer, MetadataPlayerApiFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataPlayer, MetadataPlayerApiFilters, MetadataPlayerApiResponse>({
    generateUrl: playersWithFiltersUrl,
    key: PLAYERS_QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformPlayersResponse,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidatePlayers = () => {
  const queryClient = useQueryClient();

  return useCallback(() => queryClient.invalidateQueries({ queryKey: [PLAYERS_QUERY_KEY] }), [queryClient]);
};
