import { Stack, Typography } from '@mui/material';

import { MetadataVenue } from 'pages/backoffice/api/venues/use-venues/types';

export const VenueName = ({ venue }: { venue: MetadataVenue }) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
      <Typography variant={'body1'}>{venue.name}</Typography>
      <Typography variant={'caption'}>{venue.city}</Typography>
    </Stack>
  );
};
