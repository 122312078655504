import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { AnnotationsApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';
import { getQueryParams } from 'pages/backoffice/utils/getQueryParams';

interface AnnotationTaskParams {
  data: AnnotationsApiParams;
}

export const useAnnotationTask = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Annotations task success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Annotations task error'),
  });

  const annotateTask = ({ data }: AnnotationTaskParams) => {
    const queryParams = getQueryParams<AnnotationsApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;
    mutationRequest.mutate({ url: `${annotationApiUrls.ANNOTATIONS_TASK}?${queryParams}` });
  };

  return { ...mutationRequest, annotateTask };
};
