import { nativeEnum, string, object, z } from 'zod';

import { MetadataOrganizationApi } from 'pages/backoffice/api/organizations/types';
import { MetadataSeasonSimpleApi } from 'pages/backoffice/api/seasons/types';
import {
  MetadataCompetitionFormat,
  MetadataCompetitionGender,
  MetadataCompetitionRegion,
} from 'pages/backoffice/types/metadata-competitions';
import { MetadataMappingProvider } from 'pages/backoffice/types/metadata-mappings';
import { MetadataTeamTypes } from 'pages/backoffice/types/metadata-teams';

export const metadataCompetitionMappingSchema = object({
  provider: nativeEnum(MetadataMappingProvider),
  competition_id_provider: string(),
});

export type MetadataCompetitionMappingApi = z.infer<typeof metadataCompetitionMappingSchema>;
export interface MetadataCompetitionApi {
  uuid: string;
  name: string;
  country: string | null;
  seasons: MetadataSeasonSimpleApi[];
  logo_url: string;
  format: MetadataCompetitionFormat | null;
  gender: MetadataCompetitionGender | null;
  tier: number | null;
  region: MetadataCompetitionRegion | null;
  team_type: MetadataTeamTypes | null;
  organization: MetadataOrganizationApi | null;
  mappings: MetadataCompetitionMappingApi[];
}
