import { z } from 'zod';

import { metadataMappingSchema } from 'pages/backoffice/types/metadata-mappings';

export const metadataCoachSchema = z.object({
  id: z.string(),
  name: z.string(),
  photoUrl: z.string().nullable().optional(),
  mappings: z.array(metadataMappingSchema).optional(),
});

export type MetadataCoach = z.infer<typeof metadataCoachSchema>;
