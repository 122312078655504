import { z } from 'zod';

import { metadataVenueSchema } from 'pages/backoffice/api/venues/use-venues/types';
import { metadataCoachSchema } from 'pages/backoffice/types/metadata-coaches';
import { metadataMappingSchema } from 'pages/backoffice/types/metadata-mappings';
import { metadataPlayerFixturesSchema } from 'pages/backoffice/types/metadata-players';
import { metadataRecordingSummarySchema } from 'pages/backoffice/types/metadata-recordings';
import { metadataSeasonSchema } from 'pages/backoffice/types/metadata-seasons';
import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';

export const metadataTeamSchema = z.object({
  name: z.string(),
  abbreviation: z.string(),
  logoUrl: z.string().optional().nullable(),
  teamType: z.string(),
  countryCode: z.string().optional(),
  id: z.string(),
});

export type MetadataTeam = z.TypeOf<typeof metadataTeamSchema>;

export const metadataFixturesPlayerSchema = z.object({
  position: z.number(),
  side: z.number(),
  jerseyNumber: z.number(),
  name: z.string(),
  id: z.string(),
  photoUrl: z.string().optional().nullable(),
  teamId: z.string(),
  mappings: z.array(metadataMappingSchema).optional().nullable(),
});

export type MetadataFixturePlayer = z.infer<typeof metadataFixturesPlayerSchema>;

export const fixturesPlayerSchema = z.object({
  position: z.number().optional(),
  side: z.number().optional(),
  jerseyNumber: z.number().optional(),
  name: z.string(),
  id: z.string(),
  photoUrl: z.string().optional().nullable(),
  teamId: z.string().optional(),
  mappings: z.array(metadataMappingSchema).optional().nullable(),
  fixtures: z.array(metadataPlayerFixturesSchema).optional(),
});

export type FixturesPlayer = z.infer<typeof fixturesPlayerSchema>;

export const metadataFixtureSchema = z.object({
  name: z.string(),
  duration: z.number(),
  homeTeamScore: z.number(),
  awayTeamScore: z.number(),
  date: z.string().optional(),
  pitchLength: z.number(),
  pitchWidth: z.number(),
  homePenaltyScore: z.number(),
  awayPenaltyScore: z.number(),
  matchEndType: z.nativeEnum(FixtureMatchEndType),
  id: z.string(),
  homeTeam: metadataTeamSchema,
  awayTeam: metadataTeamSchema,
  homeCoach: metadataCoachSchema.optional(),
  awayCoach: metadataCoachSchema.optional(),
  venue: metadataVenueSchema.optional(),
  stage: z.object({
    name: z.string(),
    orderIndex: z.number(),
    id: z.string(),
  }),
  season: metadataSeasonSchema,
  players: z.array(metadataFixturesPlayerSchema),
  recordings: z.array(metadataRecordingSummarySchema),
  mappings: z.array(metadataMappingSchema).optional(),
});

export type MetadataFixture = z.infer<typeof metadataFixtureSchema>;
