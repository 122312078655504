import { zodResolver } from '@hookform/resolvers/zod';
import { IconUser } from 'kognia-ui/icons/IconUser';
import React, { useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useInvalidateCoaches } from 'pages/backoffice/api/coaches/use-coaches/useCoaches';
import { useCreateCoach } from 'pages/backoffice/api/coaches/useCreateCoach';
import { useUpdateCoach } from 'pages/backoffice/api/coaches/useUpdateCoach';
import { CoachesForm } from 'pages/backoffice/pages/coaches/ui/coach-form/CoachesForm';
import { coachFormSchema } from 'pages/backoffice/pages/coaches/ui/coach-form/form';
import { CoachForm, CoachFormFieldsNames, CoachFormSchema } from 'pages/backoffice/pages/coaches/ui/coach-form/types';
import { CoachPhotoPreview } from 'pages/backoffice/pages/coaches/ui/coach-photo-preview/CoachPhotoPreview';
import { MetadataCoach } from 'pages/backoffice/types/metadata-coaches';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';

interface Props {
  open: boolean;
  onClose: () => void;
  coach?: MetadataCoach;
}

const coachesFormId = 'coaches-form';

export const CoachModal = ({ open, onClose, coach }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const invalidateCoaches = useInvalidateCoaches();

  const defaultValues = useMemo(
    () =>
      coach
        ? {
            [CoachFormFieldsNames.id]: coach.id,
            [CoachFormFieldsNames.name]: coach.name,
          }
        : undefined,
    [coach],
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    trigger,
  } = useForm<CoachFormSchema>({
    resolver: zodResolver(coachFormSchema),
    defaultValues,
  });

  const { create } = useCreateCoach();
  const { update } = useUpdateCoach();

  const coachImage = watch(CoachFormFieldsNames.coachImage);
  const photoUrlValue =
    coachImage && coachImage.length > 0 ? URL.createObjectURL(coachImage[0]) : coach ? coach.photoUrl : undefined;

  const handleCreate = useCallback(
    (data: CoachForm) => {
      create({
        data: {
          ...data,
          image: data[CoachFormFieldsNames.coachImage] ? data[CoachFormFieldsNames.coachImage][0] : undefined,
        },
        onSuccess: () => {
          invalidateCoaches();
          onClose();
          reset();
        },
      });
    },
    [create, invalidateCoaches, onClose, reset],
  );

  const handleUpdate = useCallback(
    (data: CoachForm) => {
      if (!data?.id) return;
      const { id, ...rest } = data;
      update({
        data: {
          ...rest,
          image: rest[CoachFormFieldsNames.coachImage] ? rest[CoachFormFieldsNames.coachImage][0] : undefined,
        },
        id,
        onSuccess: () => {
          invalidateCoaches();
          onClose();
        },
      });
    },
    [invalidateCoaches, onClose, update],
  );

  const handleCreateOrUpdate = useCallback(
    (data: CoachForm) => {
      if (defaultValues) {
        handleUpdate(data);
      } else {
        handleCreate(data);
      }
    },
    [defaultValues, handleCreate, handleUpdate],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit coach' : 'New coach'}
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={coachesFormId}
    >
      <form id={coachesFormId} ref={formRef} onSubmit={handleSubmit(handleCreateOrUpdate)}>
        <CoachesForm<CoachFormSchema> errors={errors} register={register} watch={watch} trigger={trigger} />
        <CoachPhotoPreview photoUrl={photoUrlValue} />
      </form>
    </DialogNew>
  );
};
