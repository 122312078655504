import { Stack } from '@mui/material';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { transformMetadataFixture } from 'pages/backoffice/api/fixtures/transformers';
import { FixtureApi } from 'pages/backoffice/api/fixtures/types';
import { addUpdatePlayersToFixturesUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { MetadataFixture } from 'pages/backoffice/types/metadata-fixtures';

type PlayerParam = {
  jersey_number: number;
  side: number | undefined;
  position: number | undefined;
  player_uuid: string;
  team_uuid: string;
};

interface RequestData {
  players: PlayerParam[];
  fixtureId: string;
  onSuccess?: (data: MetadataFixture) => void;
  onError?: () => void;
}

type MetadataAddPlayerToFixturesApiError = {
  loc: [string, string, number, string];
  msg: string;
  type: string;
};

type MetadataAddPlayerToFixturesApiErrorResponse = {
  detail: MetadataAddPlayerToFixturesApiError[] | string;
};

const addPlayersToFixtureErrorParser = (
  data: MetadataAddPlayerToFixturesApiErrorResponse | undefined,
  fallbackErrorMessage: string,
) => {
  if (!data) return fallbackErrorMessage;

  if (typeof data.detail === 'string') {
    return data.detail;
  }

  return data ? (
    <Stack>
      {data.detail.map((error, idx) => (
        <div key={idx}>{`${error.msg}: Player ${error.loc[2]} ${error.loc[3]}`}</div>
      ))}
    </Stack>
  ) : (
    fallbackErrorMessage
  );
};

type Options = {
  successMessage?: string;
};

export const useAddPlayersToFixture = (
  { successMessage }: Options = { successMessage: 'Players added to fixture' },
) => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<FixtureApi, MetadataFixture, MetadataAddPlayerToFixturesApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: successMessage,
    errorMessage: (data) => addPlayersToFixtureErrorParser(data, 'Adding players to fixture error'),
    transformer: transformMetadataFixture,
  });

  const create = ({ fixtureId, players, onSuccess, onError }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: addUpdatePlayersToFixturesUrl(fixtureId),
        data: { players },
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  return { ...mutationRequest, create };
};
