import { Stack } from '@mui/material';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useTeamWithDefaults } from 'entities/dashboard/api/use-team-with-defaults/useTeamWithDefaults';
import { TeamInfo } from 'features/dashboard/team-profile-header/ui/TeamInfo';
import { invariant } from 'shared/utils/invariant';

export const TeamProfileHeaderFeature = memo(() => {
  const { teamId } = useParams();
  invariant(teamId);
  const { data: team } = useTeamWithDefaults(teamId);

  return (
    <Stack direction={'row'}>
      <TeamInfo team={team} />
    </Stack>
  );
});

TeamProfileHeaderFeature.displayName = 'TeamProfileHeaderFeature';
