import { useLocation } from 'react-router-dom';

import { getCurrentStepFromPathName, useCheckValidateStep } from 'pages/backoffice/pages/fixtures/fixture-form/config';
import { TeamForm } from 'pages/backoffice/pages/fixtures/fixture-form/ui/team-form/TeamForm';
import { MatchTeamTypes } from 'shared/types/match';

export const HomeTeamForm = () => {
  const location = useLocation();
  const step = getCurrentStepFromPathName(location.pathname);

  useCheckValidateStep(step);

  return <TeamForm teamType={MatchTeamTypes.HOME} step={step} />;
};
