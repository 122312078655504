import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { ProcessEpisodeApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';
import { getQueryParams } from 'pages/backoffice/utils/getQueryParams';

interface ProcessEpisodeParams {
  data: ProcessEpisodeApiParams;
}

export const useProcessEpisode = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Process episode success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Process episode error'),
  });

  const processEpisode = ({ data }: ProcessEpisodeParams) => {
    const queryParams = getQueryParams<ProcessEpisodeApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.PROCESS_EPISODE}?${queryParams}` });
  };

  return { ...mutationRequest, processEpisode };
};
