import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { useInvalidateOrganizations } from 'pages/backoffice/api/organizations/useOrganizations';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';

interface RemoveParams {
  id: string;
  onSuccess?: () => void;
}

export const useRemoveOrganization = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateOrganizations = useInvalidateOrganizations();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: 'Delete organization success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Delete organization error'),
    onSuccess: invalidateOrganizations,
  });

  const remove = ({ id, onSuccess }: RemoveParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.ORGANIZATIONS}/${id}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, remove };
};
