import { List } from '@mui/material';

import { CoachItem } from 'pages/backoffice/pages/coaches/ui/coach-item/CoachItem';
import { MetadataCoach } from 'pages/backoffice/types/metadata-coaches';

interface Props {
  coaches: MetadataCoach[];
}

export const CoachesList = ({ coaches }: Props) => {
  const coachesItems = coaches.map((coach) => {
    return <CoachItem key={coach.id} coach={coach} />;
  });

  return <List>{coachesItems}</List>;
};
