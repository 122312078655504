import { List } from '@mui/material';

import { SeasonCompetitionItem } from 'pages/backoffice/pages/season-competitions/components/season-competition-item';
import { MetadataSeason } from 'pages/backoffice/types/metadata-seasons';

interface Props {
  seasons: MetadataSeason[];
}

export const SeasonCompetitionsList = ({ seasons }: Props) => {
  const seasonsItems = seasons.map((season) => {
    return <SeasonCompetitionItem key={season.id} season={season} />;
  });

  return <List>{seasonsItems}</List>;
};
