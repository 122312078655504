import { useCallback } from 'react';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { favoritesUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { invalidateTeamWithDefaults } from 'entities/dashboard/api/use-team-with-defaults/useTeamWithDefaults';
import { invalidateTeamsInfinityQuery } from 'entities/dashboard/api/use-teams-infinity-query/useTeamsInfinityQuery';
import { MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';

interface UpdateTeamFavoritesParams {
  teamId: string;
  isFavorite: boolean;
  onSuccess?: () => void;
  onMutate?: (teamId: string, isFavorite: boolean) => void;
}

export const useUpdateTeamFavorites = () => {
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
  });

  const updateTeamFavorites = useCallback(
    ({ teamId, isFavorite, onMutate, onSuccess }: UpdateTeamFavoritesParams) => {
      onMutate && onMutate(teamId, isFavorite);

      mutationRequest.mutate(
        { url: favoritesUrl('teams', teamId), data: { isFavorite } },
        {
          onSuccess: () => {
            onSuccess?.();
          },
          onSettled: async () => {
            await Promise.allSettled([invalidateTeamsInfinityQuery(), invalidateTeamWithDefaults(teamId)]);
          },
        },
      );
    },
    [mutationRequest],
  );

  return { ...mutationRequest, updateTeamFavorites };
};
