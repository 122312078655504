import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { useInvalidateOrganizations } from 'pages/backoffice/api/organizations/useOrganizations';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';
import { OrganizationFormSchema } from 'pages/backoffice/features/organization-form/types/organizationForm.types';

interface RequestData {
  data: OrganizationFormSchema;
  id: string;
  onSuccess?: () => void;
}

export const useUpdateOrganization = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateOrganizations = useInvalidateOrganizations();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Organization updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Organization update error'),
    onSuccess: invalidateOrganizations,
  });

  const update = ({ data: { image, ...rest }, id, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('organization', JSON.stringify(rest));

    if (image?.[0]) {
      formData.append('organization_image', image[0]);
    }

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.ORGANIZATIONS}/${id}`,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
