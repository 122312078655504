import { Avatar, Box, ListItem, Stack, Typography } from '@mui/material';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { IconTimeline } from 'kognia-ui/icons/IconTimeline';
import { useCallback, useState } from 'react';

import { useRemoveOrganization } from 'pages/backoffice/api/organizations/useRemoveOrganization';
import { OrganizationFormValues } from 'pages/backoffice/features/organization-form/types/organizationForm.types';
import { OrganizationDetailsDialog } from 'pages/backoffice/features/OrganizationDetailsDialog';
import { OrganizationFormModalFeature } from 'pages/backoffice/features/OrganizationFormModal.feature';
import { MetadataOrganization } from 'pages/backoffice/types/metadata-organizations';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';

interface Props {
  organization: MetadataOrganization;
}

export const OrganizationItem = ({ organization }: Props) => {
  const [isOrganizationFormModalOpen, setIsOrganizationFormModalOpen] = useState(false);
  const [isOrganizationDetailsDialogOpen, setIsOrganizationDetailsDialogOpen] = useState(false);
  const [isConfirmRemoveDialogOpen, setIsConfirmRemoveDialogOpen] = useState(false);
  const { remove } = useRemoveOrganization();

  const defaultValues: OrganizationFormValues = {
    name: organization.name,
  };

  const options: MenuListOption[] = [
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: () => setIsOrganizationDetailsDialogOpen(true),
    },
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => setIsOrganizationFormModalOpen(true),
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmRemoveDialogOpen(true),
    },
  ];

  const handleRemoveOrganization = useCallback(() => {
    remove({
      id: organization.id,
    });
  }, [organization.id, remove]);

  return (
    <>
      <ListItem
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          padding: (theme) => theme.spacing(1, 2, 1, 3),
          justifyContent: 'space-between',
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <Box mr={2}>
            <Avatar src={organization.logoUrl ?? undefined} sx={{ width: 30, height: 30 }} />
          </Box>
          <Typography fontWeight='500'>{organization.name}</Typography>
        </Stack>
        <KebabMenu options={options} />
      </ListItem>
      {isOrganizationFormModalOpen && (
        <OrganizationFormModalFeature
          open={isOrganizationFormModalOpen}
          defaultValues={defaultValues}
          id={organization.id}
          isEdit={true}
          onClose={() => setIsOrganizationFormModalOpen(false)}
        />
      )}
      {isConfirmRemoveDialogOpen && (
        <ConfirmDialog
          type={'error'}
          title={`You sure you want delete "${organization.name}" organization?`}
          cancelLabel={'Cancel'}
          confirmLabel={'Delete'}
          onConfirm={handleRemoveOrganization}
          isOpen={isConfirmRemoveDialogOpen}
          setIsOpen={setIsConfirmRemoveDialogOpen}
        />
      )}
      {isOrganizationDetailsDialogOpen && (
        <OrganizationDetailsDialog
          organization={organization}
          onClose={() => setIsOrganizationDetailsDialogOpen(false)}
        />
      )}
    </>
  );
};
