import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import React, { useCallback, useState } from 'react';

import { useDeleteStage } from 'pages/backoffice/api/stages/use-delete-stage/useDeleteStage';
import { StageFormFieldsNames, StageFormSchema } from 'pages/backoffice/pages/stages/components/stage-form/types';
import { StagesListItem } from 'pages/backoffice/pages/stages/components/stage-item/styled';
import { adjustOrderIndexForDisplay } from 'pages/backoffice/pages/stages/utils';
import { MetadataStage } from 'pages/backoffice/types/metadata-stages';
import { StageModal } from 'pages/backoffice/ui/StageModal';
import { printSummarySeasonName } from 'pages/backoffice/utils/printSeasonName';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';

interface Props {
  stage: MetadataStage;
}

export const StageItem = ({ stage }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteStage } = useDeleteStage();

  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteStage = useCallback(() => {
    if (!stage.season.id) return;

    deleteStage({ stageId: stage.id, seasonId: stage.season.id });
  }, [stage.id, stage.season.id, deleteStage]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const stageValues: StageFormSchema = {
    [StageFormFieldsNames.id]: stage.id,
    [StageFormFieldsNames.name]: stage.name,
    [StageFormFieldsNames.orderIndex]: stage.orderIndex ? stage.orderIndex : 0,
    [StageFormFieldsNames.seasonId]: stage.season.id,
    [StageFormFieldsNames.parentStageId]: stage.parentStage?.id ? stage.parentStage.id : undefined,
  };

  return (
    <>
      <StagesListItem key={stage.id}>
        <Typography fontWeight={fontWeight['500']}>{stage.name}</Typography>
        <Typography>{adjustOrderIndexForDisplay(stage.orderIndex)}</Typography>
        <Typography>{printSummarySeasonName(stage.season)}</Typography>
        <Typography>{stage.parentStage?.name}</Typography>

        <KebabMenu options={options} />
      </StagesListItem>

      {isEditModalOpen ? (
        <StageModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={stageValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${stage.name}" stage?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteStage}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
