import { List } from '@mui/material';

import { StageItem } from 'pages/backoffice/pages/stages/components/stage-item';
import { MetadataStage } from 'pages/backoffice/types/metadata-stages';

interface Props {
  stages: MetadataStage[];
}

export const StagesList = ({ stages }: Props) => {
  const stagesItems = stages.map((stage) => {
    return <StageItem key={stage.id} stage={stage} />;
  });

  return <List>{stagesItems}</List>;
};
