import { array, boolean, number, object, string, TypeOf } from 'zod';

export const metadataSeasonSummarySchema = object({
  id: string(),
  year: number(),
  name: string(),
  crossesYear: boolean(),
});

const metadataCompetitionSimpleSchema = object({
  id: string(),
  name: string(),
  country: string().nullable(),
  logoUrl: string().nullable().optional(),
});

export const metadataStageSimpleSchema = object({
  name: string(),
  orderIndex: number(),
  id: string(),
});

export const metadataStageSummarySchema = object({
  name: string(),
  orderIndex: number(),
  id: string(),
  parentStage: metadataStageSimpleSchema.optional().nullable(),
  childStages: array(metadataStageSimpleSchema).optional().nullable(),
});

export const metadataSeasonSchema = object({
  competition: metadataCompetitionSimpleSchema,
  crossesYear: boolean(),
  endDate: string().date(),
  id: string(),
  name: string(),
  startDate: string().date(),
  year: number(),
  stages: array(metadataStageSummarySchema).optional(),
});

export type MetadataSeason = TypeOf<typeof metadataSeasonSchema>;

export type MetadataSeasonSummary = TypeOf<typeof metadataSeasonSummarySchema>;
