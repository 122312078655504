import { Player, AppPlayerApi } from 'shared/types/player/player';
import { playerSchema } from 'shared/types/player/playerSchema';

export const transformPlayerApiResponse = (player: AppPlayerApi): Player => {
  return {
    id: player.uuid,
    name: player.name,
    photoUrl: player.photo_url,
    fixtures: player.fixtures.map((fixture) => ({
      id: fixture.uuid,
      name: fixture.name,
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      homePenaltyScore: fixture.home_penalty_score,
      awayPenaltyScore: fixture.away_penalty_score,
      matchEndType: fixture.match_end_type,
      position: fixture.position,
      side: fixture.side,
      jerseyNumber: fixture.jersey_number,
      date: fixture.date,
      team: {
        id: fixture.team.uuid,
        name: fixture.team.name,
        abbreviation: fixture.team.abbreviation,
        logoUrl: fixture.team.logo_url,
        teamType: fixture.team.team_type,
        countryCode: fixture.team.country_code,
      },
    })),
    isFavorite: player.is_favorite ?? false,
    competition: {
      country: player.competition.country,
      id: player.competition.uuid,
      name: player.competition.name,
      format: player.competition.format,
      organization: player.competition.organization
        ? {
            id: player.competition.organization.uuid,
            name: player.competition.organization.name,
            logoUrl: player.competition.organization.logo_url,
          }
        : null,
      seasons: player.competition.seasons.map(({ uuid, crosses_year, name, year }) => ({
        id: uuid,
        crossesYear: crosses_year,
        name,
        year,
      })),
    },
    season: {
      competition: {
        country: player.season.competition.country,
        id: player.season.competition.uuid,
        name: player.season.competition.name,
      },
      crossesYear: player.season.crosses_year,
      id: player.season.uuid,
      name: player.season.name,
      stages: player.season.stages.map(({ name, child_stages, order_index, parent_stage, uuid }) => ({
        name,
        id: uuid,
        childStages: child_stages.map((child_stage) => ({
          id: child_stage.uuid,
          name: child_stage.name,
          orderIndex: child_stage.order_index,
        })),
        orderIndex: order_index,
        parentStage: parent_stage
          ? {
              id: parent_stage?.uuid,
              name: parent_stage?.name,
              orderIndex: parent_stage?.order_index,
            }
          : null,
      })),
      year: player.season.year,
    },
    team: {
      id: player.team.uuid,
      name: player.team.name,
      teamType: player.team.team_type,
      abbreviation: player.team.abbreviation,
      countryCode: player.team.country_code,
      logoUrl: player.team.logo_url,
    },
  };
};

export const transformPlayersApiResponseWithValidation = (players: AppPlayerApi[]): Player[] => {
  return players.map((player) => {
    const transformedPlayer = transformPlayerApiResponse(player);

    const validationResult = playerSchema.safeParse(transformedPlayer);
    if (validationResult.error) {
      throw new Error('Invalid Player type', { cause: validationResult.error });
    }

    return transformedPlayer;
  });
};
