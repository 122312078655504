import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useInvalidateStages } from 'pages/backoffice/api/stages/use-stages/useStages';
import { UpdateStageApiParams } from 'pages/backoffice/api/stages/use-update-stage/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';

interface RequestData {
  data: UpdateStageApiParams;
  stageId: string;
  onSuccess?: () => void;
}

export const useUpdateStage = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateStages = useInvalidateStages();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Stage updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Stage update error'),
    onSuccess: invalidateStages,
  });

  const update = ({ data, stageId, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.STAGES}/${stageId}`,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
