import { List } from '@mui/material';

import { OrganizationItem } from 'pages/backoffice/features/organizations-list/ui/OrganizationItem';
import { MetadataOrganization } from 'pages/backoffice/types/metadata-organizations';

interface Props {
  organizations: MetadataOrganization[];
}

export const OrganizationsList = ({ organizations }: Props) => {
  return (
    <List>
      {organizations.map((organization) => (
        <OrganizationItem key={organization.id} organization={organization} />
      ))}
    </List>
  );
};
