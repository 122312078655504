import { z } from 'zod';

import { metadataCompetitionSchema } from 'pages/backoffice/types/metadata-competitions';

export const metadataOrganizationSchema = z.object({
  name: z.string(),
  logoUrl: z.string().nullable(),
  id: z.string(),
});

export const metadataOrganizationWithCompetitionsSchema = metadataOrganizationSchema.merge(
  z.object({
    competitions: z.lazy(() => z.array(metadataCompetitionSchema)),
  }),
);

export type MetadataOrganization = z.infer<typeof metadataOrganizationWithCompetitionsSchema>;
