import { TeamApiResponseWithPlayers, TeamWithPlayers } from 'shared/types/team/team';
import { CountryCode, CountryCodeSchema } from 'shared/utils/countries';

const parseCountryCode = (countryCode?: string): CountryCode | undefined => {
  if (countryCode === undefined) return countryCode;

  return CountryCodeSchema.parse(countryCode.toUpperCase());
};

const sortPlayersByPositions = (players: TeamApiResponseWithPlayers['players']) => {
  return players.toSorted((a, b) => {
    if (a.position === 0) {
      return 1;
    }

    if (b.position === 0) {
      return -1;
    }

    return a.position - b.position;
  });
};

export const transformTeam = (team: TeamApiResponseWithPlayers): TeamWithPlayers => {
  return {
    id: team.uuid,
    name: team.name,
    abbreviation: team.abbreviation,
    logoUrl: team.logo_url,
    teamType: team.team_type,
    countryCode: parseCountryCode(team.country_code),
    fixtures: team.fixtures.map((fixture) => ({
      id: fixture.uuid,
      date: new Date(fixture.date),
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      pitchLength: fixture.pitch_length,
      pitchWidth: fixture.pitch_width,
      name: fixture.name,
      duration: fixture.duration,
    })),
    isFavorite: team.is_favorite ?? false,
    players: sortPlayersByPositions(team.players).map((player) => ({
      jerseyNumber: player.jersey_number,
      side: player.side,
      position: player.position,
      name: player.player_name,
      id: player.player_uuid,
      photoUrl: player.player_photo_url,
      teamId: player.team_uuid,
    })),
    ...(team.team_defaults
      ? {
          teamDefaults: {
            competition: {
              country: team.team_defaults.competition.country,
              id: team.team_defaults.competition.uuid,
              name: team.team_defaults.competition.name,
              logoUrl: team.team_defaults.competition.logo_url,
              format: team.team_defaults.competition.format,
              organization: team.team_defaults.competition.organization
                ? {
                    id: team.team_defaults.competition.organization.uuid,
                    name: team.team_defaults.competition.organization.name,
                    logoUrl: team.team_defaults.competition.organization.logo_url,
                  }
                : null,
              seasons: team.team_defaults.competition.seasons.map(({ uuid, crosses_year, name, year }) => ({
                id: uuid,
                crossesYear: crosses_year,
                name,
                year,
              })),
            },
            season: {
              competition: {
                country: team.team_defaults.season.competition.country,
                id: team.team_defaults.season.competition.uuid,
                name: team.team_defaults.season.competition.name,
              },
              crossesYear: team.team_defaults.season.crosses_year,
              id: team.team_defaults.season.uuid,
              name: team.team_defaults.season.name,
              stages: team.team_defaults.season.stages.map(
                ({ name, child_stages, order_index, parent_stage, uuid }) => ({
                  name,
                  id: uuid,
                  childStages: child_stages.map((child_stage) => ({
                    id: child_stage.uuid,
                    name: child_stage.name,
                    orderIndex: child_stage.order_index,
                  })),
                  orderIndex: order_index,
                  parentStage: parent_stage
                    ? {
                        id: parent_stage?.uuid,
                        name: parent_stage?.name,
                        orderIndex: parent_stage?.order_index,
                      }
                    : null,
                }),
              ),
              year: team.team_defaults.season.year,
            },
          },
        }
      : {}),
  };
};
