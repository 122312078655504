import { array, boolean, nativeEnum, number, object, string, z } from 'zod';

import { metadataCompetitionMappingSchema } from 'pages/backoffice/api/competitions/types/Competition.types';
import {
  MetadataCompetitionFormat,
  MetadataCompetitionGender,
  MetadataCompetitionRegion,
  metadataCompetitionWithSeasonsAndOrganizationsSchema,
} from 'pages/backoffice/types/metadata-competitions';
import { MetadataMappingProvider, metadataMappingSchema } from 'pages/backoffice/types/metadata-mappings';
import { metadataSeasonSchema } from 'pages/backoffice/types/metadata-seasons';
import { MetadataTeamTypes } from 'pages/backoffice/types/metadata-teams';
import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';
import { paginationSchema } from 'shared/types/pagination/paginationSchema';

export const metadataPlayerMappingSchema = object({
  provider: z.nativeEnum(MetadataMappingProvider),
  player_id_provider: string(),
});

export const metadataPlayerTeamSchema = object({
  id: string(),
  name: string(),
  abbreviation: string().optional().nullable(),
  logoUrl: string().optional().nullable(),
  countryCode: string().optional(), // TODO: change type after changing types for team
  teamType: z.nativeEnum(MetadataTeamTypes),
});

export const metadataPlayerFixturesSchema = object({
  position: number().min(0).max(10),
  side: number().min(0).max(7),
  jerseyNumber: number().min(0),
  name: string(),
  homeTeamScore: number(),
  awayTeamScore: number(),
  homePenaltyScore: number(),
  awayPenaltyScore: number(),
  matchEndType: z.enum([
    FixtureMatchEndType.EXTENDED_TIME,
    FixtureMatchEndType.GOLDEN_GOAL_RULE,
    FixtureMatchEndType.NORMAL_TIME,
    FixtureMatchEndType.PENALTY_SHOOT_OUT,
  ]),
  id: string(),
  team: metadataPlayerTeamSchema,
  date: string().nullable(),
});

export const metadataPlayerSchema = object({
  name: z.string(),
  id: z.string(),
  photoUrl: z.string().optional().nullable(),
  fixtures: z.array(metadataPlayerFixturesSchema),
  mappings: z.array(metadataMappingSchema),
  default: z
    .object({
      team: metadataPlayerTeamSchema,
      season: metadataSeasonSchema,
      competition: metadataCompetitionWithSeasonsAndOrganizationsSchema,
    })
    .optional(),
});
const playerSportMetadataApiTeamSchema = object({
  uuid: string(),
  name: string(),
  abbreviation: string().optional().nullable(),
  logo_url: string().optional().nullable(),
  country_code: string().optional(), // TODO: change type after changing types for team
  team_type: z.enum([MetadataTeamTypes.CLUB, MetadataTeamTypes.NATIONAL_TEAM]),
});

const playerSportMetadataApiCompetitionSchema = object({
  uuid: string(),
  name: string(),
  country: string(),
  format: nativeEnum(MetadataCompetitionFormat).nullable(),
  gender: nativeEnum(MetadataCompetitionGender).nullable(),
  region: z.nativeEnum(MetadataCompetitionRegion).nullable(),
  logo_url: string().nullable(),
  mappings: array(metadataCompetitionMappingSchema),
  team_type: z.nativeEnum(MetadataTeamTypes).nullable(),
  tier: number().nullable(),
  organization: object({
    uuid: string(),
    name: string(),
    logo_url: string().nullable(),
  }).nullable(),
  seasons: array(
    object({
      uuid: string(),
      year: number(),
      crosses_year: boolean(),
      name: string(),
      start_date: string().nullable(),
      end_date: string().nullable(),
    }),
  ),
});

const playerSportMetadataApiSeasonSchema = object({
  uuid: string(),
  year: number(),
  crosses_year: boolean(),
  name: string(),
  start_date: string(),
  end_date: string(),
  competition: object({
    uuid: string(),
    name: string(),
    country: string(),
    logo_url: string().nullable(),
  }),
  stages: array(
    object({
      uuid: string(),
      name: string(),
      order_index: number(),
      child_stages: array(
        object({
          uuid: string(),
          name: string(),
          order_index: number(),
        }),
      ).optional(),
      parent_stage: object({
        uuid: string(),
        name: string(),
        order_index: number(),
      }).optional(),
    }),
  ),
});

const playerApiFixturesSchema = object({
  position: number().min(0).max(10),
  side: number().min(0).max(7),
  jersey_number: number().min(0),
  name: string(),
  home_team_score: number(),
  away_team_score: number(),
  home_penalty_score: number(),
  away_penalty_score: number(),
  match_end_type: z.enum([
    FixtureMatchEndType.EXTENDED_TIME,
    FixtureMatchEndType.GOLDEN_GOAL_RULE,
    FixtureMatchEndType.NORMAL_TIME,
    FixtureMatchEndType.PENALTY_SHOOT_OUT,
  ]),
  uuid: string(),
  team: playerSportMetadataApiTeamSchema,
  date: string().nullable(),
});

export const metadataPlayerApiSchema = object({
  name: string(),
  uuid: string(),
  photo_url: string().optional().nullable(),
  mappings: array(metadataPlayerMappingSchema),
  fixtures: array(playerApiFixturesSchema),
  defaults: object({
    team: playerSportMetadataApiTeamSchema,
    season: playerSportMetadataApiSeasonSchema,
    competition: playerSportMetadataApiCompetitionSchema,
  }).optional(),
});

export type MetadataPlayerApi = z.infer<typeof metadataPlayerApiSchema>;

export const metadataPlayerApiResponseSchema = object({
  data: array(metadataPlayerApiSchema),
  page: paginationSchema,
});

export type MetadataPlayerApiResponse = z.infer<typeof metadataPlayerApiResponseSchema>;

export type MetadataPlayer = z.infer<typeof metadataPlayerSchema>;
export type MetadataPlayerMapping = z.infer<typeof metadataPlayerMappingSchema>;

export type MetadataPlayerApiFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
  isFavorite?: boolean;
};

export const appPlayerFixturesSchema = object({
  position: number().min(0).max(10),
  side: number().min(0).max(7),
  jerseyNumber: number().min(0),
  name: string(),
  homeTeamScore: number(),
  awayTeamScore: number(),
  homePenaltyScore: number(),
  awayPenaltyScore: number(),
  matchEndType: z.enum([
    FixtureMatchEndType.EXTENDED_TIME,
    FixtureMatchEndType.GOLDEN_GOAL_RULE,
    FixtureMatchEndType.NORMAL_TIME,
    FixtureMatchEndType.PENALTY_SHOOT_OUT,
  ]),
  id: string(),
  team: metadataPlayerTeamSchema,
  date: string().nullable(),
});

export type MetadataPlayerFixture = z.infer<typeof appPlayerFixturesSchema>;
