import { MetadataSeason, MetadataSeasonSummary } from 'pages/backoffice/types/metadata-seasons';

export const printSeasonName = (season: MetadataSeason) => {
  if (season.competition) {
    return `${season.competition.name} ${season.year.toString()}${
      season.crossesYear ? `-${(season.year + 1).toString().slice(2)}` : ''
    } ${season.competition.country ? `(${season.competition.country})` : ''}`;
  }

  return ` ${season.year.toString()}${season.crossesYear ? `-${(season.year + 1).toString().slice(2)}` : ''}`;
};

export const printSummarySeasonName = (season: MetadataSeasonSummary) => {
  return ` ${season.year.toString()}${season.crossesYear ? `-${(season.year + 1).toString().slice(2)}` : ''}`;
};
