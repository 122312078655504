import { z } from 'zod';

import { metadataMappingSchema } from 'pages/backoffice/types/metadata-mappings';
import { MetadataMappingProvider } from 'pages/backoffice/types/metadata-mappings';
import { Pagination } from 'shared/types/pagination/types';

type MetadataVenueMappingApi = {
  provider: MetadataMappingProvider;
  venue_id_provider: string;
};

export interface MetadataVenueApi {
  uuid: string;
  name: string;
  city: string;
  country_code: string;
  mappings: MetadataVenueMappingApi[];
}

export type MetadataVenuesFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
};

export interface MetadataVenuesApiResponse {
  data: MetadataVenueApi[];
  page: Pagination;
}

export const metadataVenueSchema = z.object({
  id: z.string(),
  name: z.string(),
  city: z.string(),
  countryCode: z.string(),
  mappings: z.array(metadataMappingSchema).optional(),
});

export type MetadataVenue = z.TypeOf<typeof metadataVenueSchema>;
