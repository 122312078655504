import { Box, Button, Fade, Stack, Typography } from '@mui/material';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconDone } from 'kognia-ui/icons/IconDone';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { useState } from 'react';

import { useDeleteRecording } from 'pages/backoffice/api/fixtures/use-delete-recording/useDeleteRecording';
import { useInvalidateMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture/useMetadataFixture';
import { useUpdateRecording } from 'pages/backoffice/api/fixtures/use-update-recording/useUpdateRecording';
import { GridListItemRecording } from 'pages/backoffice/pages/fixtures/components/grid-list-item-recording/GridListItemRecording';
import { MetadataRecordingSummary } from 'pages/backoffice/types/metadata-recordings';
import { SelectClients } from 'pages/backoffice/ui/select-clients/SelectClients';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';

export const FixtureRecording = ({
  recording,
  fixtureId,
}: {
  fixtureId: string;
  recording: MetadataRecordingSummary;
}) => {
  const invalidateMetadataFixture = useInvalidateMetadataFixture();

  const { update: updateRecording } = useUpdateRecording();
  const { deleteRecording } = useDeleteRecording();
  const [clientIds, setClientIds] = useState<string[]>(recording.clientIds);
  const [isEditing, setIsEditing] = useState(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);

  const handleCancel = () => {
    setIsEditing(false);
    setClientIds(recording.clientIds);
  };

  const handleSave = () => {
    updateRecording({
      data: { client_ids: clientIds, uuid: recording.id },
      id: recording.id,
      onSuccess: () => {
        setIsEditing(false);
        invalidateMetadataFixture(fixtureId);
      },
    });
  };

  const handleDeleteRecording = () => {
    deleteRecording({
      id: recording.id,
      onSuccess: () => {
        setIsShowConfirmDelete(false);
        invalidateMetadataFixture(fixtureId);
      },
    });
  };

  const handleMakeRecordingCanonical = () => {
    updateRecording({
      data: { is_canonical: true },
      id: recording.id,
      onSuccess: () => {
        invalidateMetadataFixture(fixtureId);
      },
    });
  };

  if (isEditing) {
    return (
      <Fade in>
        <Stack gap={2} sx={{ padding: 2 }}>
          <Box>{recording.id}</Box>
          <SelectClients clientIds={clientIds} onChange={setClientIds} />
          <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
            <Button variant='outlined' color='secondary' onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='contained' onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Fade>
    );
  }

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => setIsEditing(true),
    },
    ...(!recording.isCanonical
      ? [
          {
            displayText: 'Delete',
            icon: <IconDelete size='small' color='secondary' />,
            onClick: () => setIsShowConfirmDelete(true),
          },
          {
            displayText: 'Make recording canonical',
            icon: <IconDone size='small' color='secondary' />,
            onClick: handleMakeRecordingCanonical,
          },
        ]
      : []),
  ];

  return (
    <GridListItemRecording key={recording.id}>
      <Typography>{recording.id}</Typography>
      <Stack>
        {recording.clientIds.map((clientId) => (
          <Box key={clientId}>{clientId}</Box>
        ))}
      </Stack>
      <Box textAlign={'center'}>{recording.isCanonical ? <IconDone size={'small'} color={'primary'} /> : null}</Box>

      <KebabMenu options={options} />

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${recording.id}" recording?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteRecording}
        isOpen={isShowConfirmDelete}
        setIsOpen={setIsShowConfirmDelete}
      />
    </GridListItemRecording>
  );
};
