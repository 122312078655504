import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { MetadataCompetitionApi } from 'pages/backoffice/api/competitions/types/Competition.types';
import { CompetitionsApiResponse } from 'pages/backoffice/api/competitions/use-competitions/types';
import { chooseNextCursorValue } from 'pages/backoffice/api/utils/chooseNextCursorValue';
import { MetadataCompetition } from 'pages/backoffice/types/metadata-competitions';

export const transformCompetitions = (items: MetadataCompetitionApi[]): MetadataCompetition[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    country: item.country,
    seasons: item.seasons.map((season) => ({
      id: season.uuid,
      year: season.year,
      crossesYear: season.crosses_year,
      name: season.name,
    })),
    logoUrl: item.logo_url,
    format: item.format,
    gender: item.gender,
    tier: item.tier,
    region: item.region,
    teamType: item.team_type,
    organization: item.organization
      ? {
          id: item.organization.uuid,
          name: item.organization.name,
          logoUrl: item.organization.logo_url,
        }
      : null,
    mappings: item.mappings
      ? item.mappings.map((mapping) => ({
          provider: mapping.provider,
          idProvider: mapping.competition_id_provider,
        }))
      : undefined,
  }));
};

export const transformPaginatedCompetitions = (
  response: CompetitionsApiResponse,
): InfinityQueryDataResult<MetadataCompetition> => {
  return {
    data: {
      items: transformCompetitions(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
