import { z } from 'zod';

import { Competition } from 'shared/types/competition/competition';
import { Season } from 'shared/types/season/seasonSchema';
import { TeamApi, teamApiResponseSchema } from 'shared/types/team/teamBackendApiSchema';
import { CountryCode } from 'shared/utils/countries';

export enum TeamTypes {
  CLUB = 'club',
  NATIONAL_TEAM = 'national_team',
}

export type TeamApiBackendResponse = z.infer<typeof teamApiResponseSchema>;

export type TeamApiResponse = z.infer<typeof teamApiResponseSchema>;

export interface TeamFixture {
  name: string;
  duration: number;
  homeTeamScore: number;
  awayTeamScore: number;
  date: Date;
  pitchLength: number;
  pitchWidth: number;
  id: string;
}

export interface Team {
  name: string;
  abbreviation: string;
  logoUrl: string | null;
  teamType: TeamTypes;
  countryCode?: CountryCode;
  id: string;
  fixtures: TeamFixture[];
  isFavorite: boolean;
}

export interface TeamPlayer {
  id: string;
  jerseyNumber: number;
  name: string;
  photoUrl: string | null;
  position: number;
  side: number;
  teamId: string;
}

export interface TeamWithPlayers extends Team {
  players: TeamPlayer[];
  teamDefaults?: {
    season: Season;
    competition: Competition;
  };
}

export type TeamsFilters = {
  name?: string;
  seasonId?: string;
  sort?: string;
  sortDirection?: string;
  isFavorite?: boolean;
};

interface TeamFixtureApi {
  name: string;
  duration: number;
  home_team_score: number;
  away_team_score: number;
  date: Date;
  pitch_length: number;
  pitch_width: number;
  uuid: string;
}

type TeamWithFixturesApi = TeamApi & {
  fixtures: TeamFixtureApi[];
};

interface TeamPlayerApiResponse {
  jersey_number: number;
  side: number;
  position: number;
  player_name: string;
  player_uuid: string;
  player_photo_url: string | null;
  team_uuid: string;
}

export interface TeamApiResponseWithPlayers extends TeamWithFixturesApi {
  players: TeamPlayerApiResponse[];
}
