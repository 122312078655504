import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import React, { useCallback, useState } from 'react';

import { useDeleteVenue } from 'pages/backoffice/api/venues/use-delete-venue/useDeleteVenue';
import { useUpdateVenue } from 'pages/backoffice/api/venues/use-update-venue/useUpdateVenue';
import { MetadataVenue } from 'pages/backoffice/api/venues/use-venues/types';
import { useInvalidateVenues } from 'pages/backoffice/api/venues/use-venues/useVenues';
import { VenueFormFieldsNames } from 'pages/backoffice/pages/venues/components/venue-form/types';
import { VenuesListItem } from 'pages/backoffice/pages/venues/components/venue-item/styled';
import { VenueModal } from 'pages/backoffice/pages/venues/components/venue-modal';
import { MetadataMapping } from 'pages/backoffice/types/metadata-mappings';
import { MappingsModal } from 'pages/backoffice/ui/mappings-modal/MappingsModal';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';
import { Countries } from 'shared/utils/countries';

interface Props {
  venue: MetadataVenue;
}

export const VenueItem = ({ venue }: Props) => {
  const { update, isPending } = useUpdateVenue();
  const invalidateVenues = useInvalidateVenues();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteVenue } = useDeleteVenue();

  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const [isMappingsModalOpen, setIsMappingsModalOpen] = useState(false);
  const handleMappingsModalOpen = () => setIsMappingsModalOpen(true);
  const handleMappingsModalClose = () => setIsMappingsModalOpen(false);

  const handleDeleteVenue = useCallback(() => {
    deleteVenue({ id: venue.id });
  }, [venue.id, deleteVenue]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Mappings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleMappingsModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const defaultValues = {
    [VenueFormFieldsNames.id]: venue.id,
    [VenueFormFieldsNames.name]: venue.name,
    [VenueFormFieldsNames.city]: venue.city ? venue.city : '',
    [VenueFormFieldsNames.countryCode]: Countries.map((country) => country.code).some(
      (countryCode) => countryCode === venue.countryCode,
    )
      ? venue.countryCode
      : '',
  };

  const handleUpdateMappings = useCallback(
    (mappings: MetadataMapping[]) => {
      update({
        id: venue.id,
        data: {
          mappings: mappings
            .filter((mapping) => mapping.idProvider)
            .map((mapping) => ({
              provider: mapping.provider,
              venue_id_provider: mapping.idProvider,
            })),
        },
        onSuccess: () => {
          invalidateVenues();
        },
      });
    },
    [update, venue.id, invalidateVenues],
  );

  return (
    <>
      <VenuesListItem key={venue.id}>
        <Typography fontWeight={fontWeight['500']}>{venue.name}</Typography>
        <Typography>{venue.city}</Typography>
        <Typography>
          {Countries.find((item) => item.code.toUpperCase() === venue.countryCode?.toUpperCase())?.name || ''}
        </Typography>
        <KebabMenu options={options} />
      </VenuesListItem>

      {isEditModalOpen ? (
        <VenueModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={defaultValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${venue.name}" venue?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteVenue}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />

      {isMappingsModalOpen ? (
        <MappingsModal
          id={venue.id}
          isLoading={isPending}
          mappings={venue.mappings}
          onClose={handleMappingsModalClose}
          title={`Venue mappings - ${venue.name}`}
          update={handleUpdateMappings}
        />
      ) : null}
    </>
  );
};
