import { useCallback } from 'react';

import { MetadataSeason } from 'pages/backoffice/types/metadata-seasons';

export const useOnChangeSeasonSelection = (
  onChange: (season: MetadataSeason) => void,
  setAutocompleteValue: (season: MetadataSeason) => void,
) => {
  return useCallback(
    (results: MetadataSeason[], selectedSeason?: MetadataSeason) => {
      const updatedSelectedSeason = results.find((s) => s.id === selectedSeason?.id);
      if (updatedSelectedSeason) {
        onChange(updatedSelectedSeason);
        setAutocompleteValue(updatedSelectedSeason);
      }
    },
    [onChange, setAutocompleteValue],
  );
};
