import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { useInvalidateOrganizations } from 'pages/backoffice/api/organizations/useOrganizations';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils/metadataApiErrorParser';
import { OrganizationFormSchema } from 'pages/backoffice/features/organization-form/types/organizationForm.types';

interface RequestData {
  data: OrganizationFormSchema;
  onSuccess?: () => void;
}

export const useCreateOrganization = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateOrganizations = useInvalidateOrganizations();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Organization created',
    errorMessage: (data) => metadataApiErrorParser(data, 'Organization creation error'),
    onSuccess: invalidateOrganizations,
  });

  const create = ({ data: { image, ...rest }, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('organization', JSON.stringify(rest));

    if (image?.[0]) {
      formData.append('organization_image', image[0]);
    }

    mutationRequest.mutate(
      {
        url: annotationApiUrls.ORGANIZATIONS,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
