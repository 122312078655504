import { Box, Stack } from '@mui/material';
import { IconBall } from 'kognia-ui/icons/IconBall';
import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  getCurrentStepFromPathName,
  useResetFixtureFormData,
} from 'pages/backoffice/pages/fixtures/fixture-form/config';
import { FixtureFormNavigation } from 'pages/backoffice/pages/fixtures/fixture-form/ui/fixture-form-navigation/FixtureFormNavigation';
import FixtureFormStepper from 'pages/backoffice/pages/fixtures/fixture-form/ui/fixture-form-stepper/FixtureFormStepper';
import { BackofficeSection } from 'pages/backoffice/ui/BackofficeSection';

const getPageTitle = (id: string | undefined, step: number) => {
  if (step === 3) return 'FIXTURE SUMMARY';

  return id ? 'EDIT FIXTURE' : 'NEW FIXTURE';
};

export const FixtureForm = ({ children }: { children: ReactNode }) => {
  const { id } = useParams();
  const currentStep = getCurrentStepFromPathName(location.pathname);

  const resetNewFixtureData = useResetFixtureFormData();

  useEffect(() => {
    if (!id) resetNewFixtureData();
  }, [id, resetNewFixtureData]);

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column', background: '#fff' }}>
      <Stack sx={{ flexGrow: 1, overflowY: 'scroll', padding: '0 32px 0px' }} gap={4}>
        <BackofficeSection icon={<IconBall size='small' />}>{getPageTitle(id, currentStep)}</BackofficeSection>
        <FixtureFormStepper step={currentStep} />
        {children}
      </Stack>
      <FixtureFormNavigation step={currentStep} />
    </Box>
  );
};
