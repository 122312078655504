import { Box, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { CompetitionAvatar } from 'kognia-ui/components/avatar/CompetitionAvatar';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { optimisticFavoritesUpdateTeamWithDefaults } from 'entities/dashboard/api/use-team-with-defaults/useTeamWithDefaults';
import { useUpdateTeamFavorites } from 'entities/dashboard/api/useUpdateTeamFavorites';
import { Country } from 'features/dashboard/team-profile-header/ui/Country';
import { FavoriteStarButton } from 'shared/components/FavoriteStarButton';
import { ProfileInfoWrapper } from 'shared/components/ProfileInfoWrapper';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';
import { TeamWithPlayers } from 'shared/types/team/team';

export const TeamInfo = ({ team }: { team: TeamWithPlayers }) => {
  const { t } = useTranslation(['common']);
  const favoritesFeatureFlag = useFeatureFlag(FEATURE_FLAG.APP_FAVORITES);
  const { updateTeamFavorites } = useUpdateTeamFavorites();

  const handleFavoritesButtonClick = useCallback(() => {
    publishEvent({
      type: ActionTypes.UPDATE_FAVORITES,
      payload: {
        name: MetricsNames.FAVORITES_UPDATE,
        data: {
          action: !team.isFavorite ? 'add' : 'remove',
          place: 'page',
          type: 'team',
        },
      },
    });
    updateTeamFavorites({
      teamId: team.id,
      isFavorite: !team.isFavorite,
      onMutate: optimisticFavoritesUpdateTeamWithDefaults,
    });
  }, [team.id, team.isFavorite, updateTeamFavorites]);

  return (
    <ProfileInfoWrapper>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <TeamAvatar size={'xlarge'} src={team.logoUrl} />
        <Stack gap={1}>
          <Typography fontSize={fontSizes.medium} fontWeight={(theme) => theme.typography.fontWeightBold}>
            {team.name}
          </Typography>
          <Country countryCode={team.countryCode} />
          {team.teamDefaults ? (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <CompetitionAvatar src={team.teamDefaults.competition.logoUrl} />
              <Typography variant={'body2'}>{team.teamDefaults.competition.name}</Typography>
            </Stack>
          ) : null}
        </Stack>

        {favoritesFeatureFlag ? (
          <Box sx={{ position: 'absolute', top: (theme) => theme.spacing(0.5), right: (theme) => theme.spacing(0.5) }}>
            <FavoriteStarButton
              isFavorite={team.isFavorite}
              onClick={handleFavoritesButtonClick}
              title={t(team.isFavorite ? 'favorites.remove' : 'favorites.add')}
            />
          </Box>
        ) : null}
      </Stack>
    </ProfileInfoWrapper>
  );
};
