import { Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import { useMemo } from 'react';

import { PlayerListHeader } from 'pages/backoffice/pages/fixtures/fixture-form/ui/player-list/player-list-header/PlayerListHeader';
import { SelectedPlayerItem } from 'pages/backoffice/pages/fixtures/fixture-form/ui/selected-players/selected-player-item/SelectedPlayerItem';
import { FixturesPlayer } from 'pages/backoffice/types/metadata-fixtures';

type Props = {
  selectedPlayers: FixturesPlayer[];
  removePlayers: (players: FixturesPlayer[]) => void;
  onUpdatePlayer: (player: FixturesPlayer) => void;
};

export const SelectedPlayersList = ({ selectedPlayers, removePlayers, onUpdatePlayer }: Props) => {
  if (selectedPlayers.length === 0) return <Typography sx={{ color: Colors.storm }}>No players selected</Typography>;

  const jerseyNumbersCount = useMemo(
    () =>
      selectedPlayers.reduce<{ [key in number]: number }>((acc, player) => {
        const jerseyNumber = player.jerseyNumber;
        if (!jerseyNumber) return acc;

        if (!acc[jerseyNumber]) {
          acc[jerseyNumber] = 1;
        } else {
          acc[jerseyNumber] += 1;
        }
        return acc;
      }, {}),
    [selectedPlayers],
  );

  return (
    <>
      <PlayerListHeader />
      {selectedPlayers.map((player) => (
        <SelectedPlayerItem
          key={player.id}
          player={player}
          removePlayers={removePlayers}
          onUpdatePlayer={onUpdatePlayer}
          hasJerseyNumberError={Boolean(player.jerseyNumber && jerseyNumbersCount[player.jerseyNumber] > 1)}
        />
      ))}
    </>
  );
};
