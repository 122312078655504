import { array, z } from 'zod';

import { metadataCompetitionMappingSchema } from 'pages/backoffice/api/competitions/types/Competition.types';
import { CompetitionFormFieldsNames } from 'pages/backoffice/pages/competitions/ui/competition-form/types';
import {
  MetadataCompetitionFormat,
  MetadataCompetitionGender,
  MetadataCompetitionRegion,
} from 'pages/backoffice/types/metadata-competitions';
import { MetadataTeamTypes } from 'pages/backoffice/types/metadata-teams';
import { transformEmptyToNull } from 'pages/backoffice/utils/transformEmptyToNull';

export const competitionFormSchema = z.object({
  [CompetitionFormFieldsNames.id]: z.string().optional(),
  [CompetitionFormFieldsNames.name]: z.string().trim().min(2),
  [CompetitionFormFieldsNames.country]: z.string().optional().transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.competitionImage]: z.instanceof(FileList).optional(),
  [CompetitionFormFieldsNames.format]: z
    .nativeEnum(MetadataCompetitionFormat)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.gender]: z
    .nativeEnum(MetadataCompetitionGender)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.tier]: z
    .string()
    .or(z.number())
    .optional()
    .transform((value) => {
      if (typeof value === 'string' && parseInt(value, 10).toString() === value) {
        return Number(value);
      }

      return transformEmptyToNull(value?.toString());
    })
    .refine((value) => value === null || (typeof value !== 'string' && Number(value) > 0), {
      message: 'Tier must be a positive number',
    }),
  [CompetitionFormFieldsNames.region]: z
    .nativeEnum(MetadataCompetitionRegion)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.teamType]: z
    .nativeEnum(MetadataTeamTypes)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.organizationId]: z.string().optional().transform(transformEmptyToNull),
});

export const competitionEditFormSchema = competitionFormSchema.omit({ [CompetitionFormFieldsNames.id]: true }).extend({
  [CompetitionFormFieldsNames.mappings]: array(metadataCompetitionMappingSchema),
});
