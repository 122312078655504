import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { chooseNextCursorValue } from 'pages/backoffice/api/utils/chooseNextCursorValue';
import {
  MetadataVenue,
  MetadataVenueApi,
  MetadataVenuesApiResponse,
} from 'pages/backoffice/api/venues/use-venues/types';

const transformData = (items: MetadataVenueApi[]): MetadataVenue[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    city: item.city,
    countryCode: item.country_code,
    mappings: item.mappings.map((mapping) => ({
      provider: mapping.provider,
      idProvider: mapping.venue_id_provider,
    })),
  }));
};

export const transformVenues = (response: MetadataVenuesApiResponse): InfinityQueryDataResult<MetadataVenue> => {
  return {
    data: {
      items: transformData(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
