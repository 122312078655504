import { string, z, object } from 'zod';

import { MetadataCompetition } from 'pages/backoffice/types/metadata-competitions';
import { MetadataMapping, MetadataMappingProvider } from 'pages/backoffice/types/metadata-mappings';
import { MetadataPlayerMapping } from 'pages/backoffice/types/metadata-players';
import { Season } from 'shared/types/season/seasonSchema';
import { CountryCode } from 'shared/utils/countries';

export enum MetadataTeamTypes {
  CLUB = 'club',
  NATIONAL_TEAM = 'national_team',
}

const teamMappingSchema = object({
  provider: z.nativeEnum(MetadataMappingProvider),
  team_id_provider: string(),
});

export type TeamMapping = z.infer<typeof teamMappingSchema>;

export interface MetadataTeamFixture {
  name: string;
  duration: number;
  homeTeamScore: number;
  awayTeamScore: number;
  date: Date;
  pitchLength: number;
  pitchWidth: number;
  id: string;
}

export interface MetadataTeam {
  name: string;
  abbreviation: string;
  logoUrl: string | null;
  teamType: MetadataTeamTypes;
  countryCode?: CountryCode;
  id: string;
  fixtures: MetadataTeamFixture[];
  mappings?: MetadataMapping[]; // TODO: remove this from APP part when new type for the app is created
}

export interface TeamPlayer {
  id: string;
  jerseyNumber: number;
  name: string;
  photoUrl: string | null;
  position: number;
  side: number;
  teamId: string;
  playerMappings?: MetadataPlayerMapping[];
}

export interface TeamWithPlayers extends MetadataTeam {
  players: TeamPlayer[];
  teamDefaults?: {
    season: Season;
    competition: MetadataCompetition;
  };
}

export type TeamsFilters = {
  name?: string;
  seasonId?: string;
  sort?: string;
  sortDirection?: string;
  isFavorite?: boolean;
};
