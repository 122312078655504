import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { StageApi, MetadataStageSummaryApi } from 'pages/backoffice/api/stages/types';
import { StagesApiResponse } from 'pages/backoffice/api/stages/use-stages/types';
import { chooseNextCursorValue } from 'pages/backoffice/api/utils/chooseNextCursorValue';
import { MetadataStage, MetadataStageSummary } from 'pages/backoffice/types/metadata-stages';

export const transformStageSummaries = (items: MetadataStageSummaryApi[]): MetadataStageSummary[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    orderIndex: item.order_index,
    ...(item.parent_stage
      ? {
          parentStage: {
            name: item.parent_stage.name,
            orderIndex: item.parent_stage.order_index,
            id: item.parent_stage.uuid,
          },
        }
      : {}),
    childStages: item.child_stages.map((childStage) => ({
      name: childStage.name,
      orderIndex: childStage.order_index,
      id: childStage.uuid,
    })),
  }));
};

export const transformStages = (items: StageApi[]): MetadataStage[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    orderIndex: item.order_index,
    season: {
      name: item.season.name,
      year: item.season.year,
      crossesYear: item.season.crosses_year,
      id: item.season.uuid,
    },
    childStages: item.child_stages.map((childStage) => ({
      name: childStage.name,
      orderIndex: childStage.order_index,
      id: childStage.uuid,
    })),
    parentStage: item.parent_stage
      ? {
          name: item.parent_stage.name,
          orderIndex: item.parent_stage.order_index,
          id: item.parent_stage.uuid,
        }
      : undefined,
  }));
};

export const transformStagesResponse = (response: StagesApiResponse): InfinityQueryDataResult<MetadataStage> => {
  return {
    data: {
      items: transformStages(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
