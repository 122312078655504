import { z } from 'zod';

import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';

export type MetadataRecording = {
  recordingType: string;
  clientIds: string[];
  id: string;
  createdAt: string;
  fixture: {
    name: string;
    duration: number;
    homeTeamScore: number;
    awayTeamScore: number;
    date: string;
    pitchLength: number;
    pitchWidth: number;
    homePenaltyScore: number;
    awayPenaltyScore: number;
    matchEndType: FixtureMatchEndType;
    id: string;
  };
};

export const metadataRecordingSummarySchema = z.object({
  recordingType: z.string(),
  clientIds: z.array(z.string()),
  id: z.string(),
  isCanonical: z.boolean(),
  createdAt: z.string(),
});

export type MetadataRecordingSummary = z.infer<typeof metadataRecordingSummarySchema>;
