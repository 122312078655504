export enum CompetitionGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum CompetitionRegion {
  DOMESTIC = 'domestic',
  INTERNATIONAL = 'international',
}

export enum CompetitionFormat {
  LEAGUE = 'league',
  CUP = 'cup',
}
